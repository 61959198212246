import { React, useState } from 'react'
import './header.scss';
import { returnErrors } from '../../Redux/ActionCreators/errorAction';
import { useDispatch } from 'react-redux';
import { emailSubscribe } from '../../Redux/ActionCreators/contactusAction';
import { useNavigate } from 'react-router-dom';

/* ######################################### Website Footer ########################### */

export default function Footer() {
    const [threatAdvisoryChecked, setThreatAdvisoryChecked] = useState(true);
    const [newsletterChecked, setNewsletterChecked] = useState(true);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setPage = (val) => {
        // window.scrollTo(0, 0);
        if (val === 'demo') {
            window.location.href = '/schedule-a-demo';
        } else if (val === 'contact') {
            window.location.href = '/contactus';
        } else if (val === 'resources') {
            window.location.href = '/resources-hub';
        } else if (val === 'advisory') {
            navigate('/resources-hub', { state: { type: 'advisory' } })
        }
        else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        }
        else if (val === 'breach') {
            window.location.href = '/experiencing-breach';
        } else if (val === 'partnerreg') {
            window.location.href = '/partner-registration';
        } else if (val === 'perceptplatform') {
            window.location.href = '/sequretek-percept-platform';
        } else if (val === 'services') {
            window.location.href = '/services';
        } else if (val === 'sequretek') {
            window.location.href = '/sequretek-benefits';
        } else if (val === 'industries') {
            window.location.href = '/industries/insurance-cybersecurity';
        } else if (val === 'newsroom') {
            window.location.href = '/latest-news';
        } else if (val === 'careers') {
            window.location.href = '/join-our-team';
        } else if (val === 'cookie') {
            window.location.href = '/cookie-policy';
        } else if (val === 'home') {
            window.location.href = '/';
        } else if (val === 'legal') {
            window.location.href = '/legal-policy';
        } else if (val === 'vdp') {
            window.location.href = '/vulnerability-disclosure-policy';
        }
        else if (val === 'privacy') {
            window.location.href = '/privacy-policy';
        } else if (val === 'story') {
            window.location.href = '/sequretek-story';
        } else if (val === 'partner') {
            window.location.href = '/cybersecurity-solution-provider-partner';
        }
    }
    const handleSubmit = async () => {
        const regex = /^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/
        if (!threatAdvisoryChecked && !newsletterChecked) {
            // returnErrors(dispatch, 'Please select one option from above options', 202);
            setError('Please select one option from above options');
        } else if (email === '') {
            setError('Please enter email');
        }
        else if (!regex.test(String(email))) {
            returnErrors(dispatch, 'Enter Only Business email address', 202);
            setError('Enter Only Business email address');
        }
        else {
            setError('');
            setEmail('')
            setLoading(true);
            let obj = {
                "threat": threatAdvisoryChecked,
                "newsletter": newsletterChecked,
                "email": email
            }
            const res = await dispatch(emailSubscribe(obj));
            if (res?.type === 'SUBSCRIBE') {
                setTimeout(() => {
                    setLoading(false)
                }, 2000);
            }
        }
    };
    const handleThreatAdvisoryChange = (e) => {
        setThreatAdvisoryChecked(e.target.checked);
        if (e.target.checked || newsletterChecked) {
            setError('');
        }
    };

    const handleNewsletterChange = (e) => {
        setNewsletterChecked(e.target.checked);
        if (e.target.checked || threatAdvisoryChecked) {
            setError('');
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://sequertek-upriselabs-storage.s3.us-east-2.amazonaws.com/resource/mediakit/Media+Kit_Sequretek.zip';
        link.download = 'Sequretek Media Kit.zip';  // This will suggest the filename for download
        link.click();
    };


    return (
        <div className='seq-footer' style={{ backgroundImage: window.innerWidth > 767 ? `url('/images/footer-bg.svg')` : `linear-gradient(2.8deg, rgba(151, 1, 2, 1) -19.85%, rgba(90, 7, 45, 1) 10.24%, rgba(14, 15, 97, 1) 47.36%, #040C30 87.75%)` }}>
            <div className='container'>
                <div className='row me-0'>
                    <div className='col-12 p-0'>
                        <img onClick={() => setPage('home')} className='seq-footer-logoimg' src='/images/Hero-Logo.svg' alt='' />
                    </div>
                    <div className={window.innerWidth > 767 ? 'seq-footer-parent px-0 col d-flex flex-column flex-sm-row justify-content-between' : 'seq-footer-parent col-9 px-0 d-flex flex-column flex-sm-row justify-content-between'}>
                        <h5 className='seq-footer-parent-text' onClick={() => setPage('demo')}>Schedule a Demo</h5>
                        <h5 className='seq-footer-parent-text' onClick={() => setPage('contact')}>Contact & Support</h5>
                        <h5 className='seq-footer-parent-text' onClick={() => setPage('partnerreg')}>Become a Partner</h5>
                        <h5 className='seq-footer-parent-text' onClick={() => setPage('datasheet')}>Download Datasheets</h5>
                        <h5 className='seq-footer-parent-text' onClick={() => setPage('advisory')}>Download Threat Advisory</h5>
                    </div>
                    {window.innerWidth > 767 ? null : <div className='seq-address-inv px-0'>
                        <button className='seq-footer-addressbtn'> <img src="/images/Vector.svg" alt='' onClick={() => setPage('breach')} /> &nbsp;Experiencing a breach?</button>
                    </div>}
                    <hr />
                    <div className={window.innerWidth > 767 ? 'seq-footer-child col d-flex flex-wrap justify-content-between' : 'seq-footer-child col-12  offset-1 d-flex flex-wrap justify-content-between'}>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('perceptplatform')}>Platform</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('services')}>Services</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('sequretek')}>Why Sequretek</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('story')}>Company</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('industries')}>Industries</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('partner')}>Partners</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('resources')}>Resources</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('newsroom')}>Newsroom</h5>
                        </div>
                        <div className='col-lg-auto'>
                            <h5 className='seq-footer-child-text' onClick={() => setPage('careers')}>Careers</h5>
                        </div>
                    </div>
                    <hr />
                    <div className='row seq-footer-address px-0'>
                        <div className={window.innerWidth > 767 ? 'col px-0' : 'd-flex justify-content-center align-items-center px-0'}>
                            <div className='row  seq-footer-multiloc mx-0'>
                                <div className='col-md-5  seq-footer-location px-0'>
                                    <img src='/images/usaflag.svg' alt='' />





                                    <p>417 Main Street<br /> Ste 501-2, Little Rock<br /> AR 72201. USA</p>
                                </div>
                                <div className='col-md-6 seq-footer-location px-0'>
                                    <img src='/images/indiaflag.svg' alt='' />
                                    <p>504, Satellite Silver<br /> Andheri Kurla Road, Andheri<br /> Mumbai 400059. India</p>
                                </div>
                            </div>
                            <div className='seq-footer-addressbtn'>
                                {window.innerWidth > 767 ? (
                                    <div className='seq-address-inv'>
                                        <button className='seq-footer-btn' onClick={() => setPage('breach')} >
                                            <img src='/images/Vector.svg' alt='' /> &nbsp;<b>Experiencing a breach?</b>
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className={window.innerWidth > 767 ? 'col' : 'd-flex justify-content-center align-items-center flex-column'} style={{ padding: '0px' }}>
                            <div className='seq-footer-sub-area'>
                                <div className='seq-footer-sub'>
                                    <p>Subscribe to the latest threat advisories and newsletters from Sequretek</p>
                                </div>
                                <div className='row seq-footer-input-area'>
                                    <div className='col-lg-6 col-12 px-0 seq-footer-input'>
                                        <input className='ceq-checkbox' type="checkbox" id="latestThreatAdvisory" checked={threatAdvisoryChecked} onChange={handleThreatAdvisoryChange} />
                                        <label htmlFor='latestThreatAdvisory'>Latest Threat Advisory</label>
                                    </div>
                                    <div className='col-lg-6 col-12 px-0 seq-footer-input'>
                                        <input className='ceq-checkbox' type='checkbox' id='newsletter' checked={newsletterChecked} onChange={handleNewsletterChange} />
                                        <label htmlFor='newsletter'>Newsletter</label>
                                    </div>
                                </div>

                            </div>
                            <div className='seq-footer-sub2'>
                                <div className='ceq-input-search'>
                                    <input type='text' placeholder='Your Business Email' className='form-control custom-input' value={email} onChange={(e) => {
                                        setEmail(e.target.value)
                                        setError('')
                                    }} />
                                    {window.innerWidth > 767 ?
                                        <div className='input-grp'>
                                            {loading ?
                                                <button type='button' className='custom-button' style={{ backgroundColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                                <button type='button' className='custom-button' onClick={handleSubmit}>Submit</button>}
                                        </div> : null
                                    }
                                </div>
                                {window.innerWidth > 767 ? null :
                                    <div className='input-grp'>
                                        {loading ?
                                            <button type='button' className='custom-button' style={{ backgroundColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                            <button type='button' className='custom-button' onClick={handleSubmit}>Submit</button>}
                                    </div>
                                }
                                {error && <p className='ms-3 mt-2' style={{ color: 'red' }}>{error}</p>}
                            </div>
                        </div>
                    </div>
                    <hr className='seq-footer-line' />
                    <div className='row seq-footer-contact px-0'>
                        <div className='col-sm-12 col-lg-auto seq-copyright'>
                            <p style={{ fontSize: '16px' }}>©2024 Sequretek</p>
                        </div>
                        <div className='col-12 col-lg-auto  d-flex flex-wrap seq-contactinfo '>
                            <p className='seq-contact' onClick={() => setPage('contact')}>Contact Us</p>
                            <p className='seq-contact' onClick={() => setPage('privacy')}>Privacy Policy</p>
                            <p className='seq-contact' onClick={() => setPage('cookie')}>Cookie Policy</p>
                            <p className='seq-contact' onClick={handleDownload}>Media Kit</p>
                            <p className='seq-contact' onClick={() => setPage('vdp')}>Vulnerability Disclosure</p>
                        </div>
                        <div className='col-12 col-lg-auto px-0 d-flex seq-contact-media justify-content-end'>
                            <a href='https://www.linkedin.com/company/sequretek' target='_blank' rel="noreferrer"><img className='seq-media-img' src='/images/linkedin-icon.svg' alt='' /></a>
                            <a href='https://x.com/sequretek_sqtk' target='_blank' rel="noreferrer"><img className='seq-media-img' src='/images/twitter-icon.svg' alt='' /></a>
                            <a href='https://www.facebook.com/Sequretek.sqtk/' target='_blank' rel="noreferrer"><img className='seq-media-img' src='/images/facebook-icon.svg' alt='' /></a>
                            <a href='https://www.instagram.com/sequretek/' target='_blank' rel="noreferrer"><img className='seq-media-img' src='/images/instagram-icon.svg' alt='' /></a>
                            <a href='https://www.youtube.com/channel/UCtmWvpcxFcvXlgqyfXAWhvQ' target='_blank' rel="noreferrer"><img className='seq-media-img' src='/images/youtube-icon.svg' alt='' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}