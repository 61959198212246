// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs8() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Case Study: JavaScript Malware Targeting Banking Sector</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Discover how a seemingly harmless email led to the discovery of a sophisticated JavaScript malware capable of downloading and executing additional payloads." />
                <meta name="keywords" content="javascript malware, malware analysis, cybersecurity, banking sector, phishing attack" />
                <meta property="og:title" content="Case Study: JavaScript Malware Targeting Banking Sector" />
                <meta property="og:description" content="Discover how a seemingly harmless email led to the discovery of a sophisticated JavaScript malware capable of downloading and executing additional payloads." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Case Study of a JavaScript Malware with Worm-like Behaviour</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Case Study of a JavaScript Malware with Worm-like Behaviour</h3>
                        <p>4-JULY-2024 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample14.jpg' width={'100%'} height={318} alt='' />
                            <h4>Case Study of a JavaScript Malware with Worm-like Behaviour</h4>
                            <h4>Executive summary</h4>
                            <p>In the ever-evolving world of cybersecurity, threats often hide in the most unsuspecting places. One such encounter began with an ordinary email, seemingly harmless, but teeming with concealed dangers.</p>
                            <p>Recently, Sequretek SOC flagged an IP address detected in a client’s environment, prompting our team to request the associated email from the client, as it was identified as malicious. Upon receiving it, the SOC analyst promptly forwarded the email to our Malware Research Team for in-depth analysis. Within the email was a link to a GitHub repository.</p>
                            <p>Upon clicking the GitHub link, a zip file was automatically downloaded. Inside this innocuous-seeming zip file lay a JavaScript file. However, this was no ordinary JavaScript; it was a sophisticated, multi-functional malware meticulously crafted to avoid detection and cause widespread damage. Among its capabilities, this malware specialized in downloading and executing additional malicious payloads, often targeting sensitive banking credentials and other critical information. Its features include:</p>
                            <ul>
                                <li><p><strong>Obfuscation:</strong> Uses techniques to avoid detection by security software.</p></li>
                                <li><p><strong>Payload Downloading:</strong> Fetches and installs additional malware.</p></li>
                                <li><p><strong>Remote Access:</strong> Allows attackers to control infected machines.</p></li>
                                <li><p><strong>Information Theft:</strong> Extracts sensitive data from the system.</p></li>
                                <li><p><strong>Persistence:</strong> Ensures the malware remains active on the infected system.</p></li>
                            </ul>
                            <img src='/images/blog-images/sample27.jpg' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 1: Attack Chain</p>
                            <h4>Analysis and Findings</h4>
                            <p>From the contents and subject of the email, we could infer that this scam targets the banking sector and utilizes a multi-stage JavaScript (JS) attack to gain persistence and potentially steal sensitive information.  Here’s a breakdown of the malware’s behaviour.</p>
                            <h4>Initial Infection Vector</h4>
                            <p>A seemingly legitimate email with a GitHub link triggers the download. Threat actors can misuse platforms like GitHub to host malicious files or disguise them as legitimate code. Recipients of such email are advised to be cautious of downloading files, even from seemingly trusted sources.</p>
                            <img src='/images/blog-images/sample28.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '85%' : '100%' }} />
                            <p>Figure 2: Email appears legit and contains a link related to GitHub</p>
                            <img src='/images/blog-images/sample29.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '75%' : '100%' }} />
                            <p>Figure 3: Github link</p>
                            <h4>Download and Installation Stages</h4>
                            <p>Clicking the link downloads a ZIP file containing a JS file.</p>
                            <img src='/images/blog-images/sample30.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 4: Link Interaction</p>
                            <img src='/images/blog-images/sample31.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '90%' : '100%' }} />
                            <p>Figure 5: ZIP File downloaded</p>
                            <Table responsive>
                                <tr>
                                    <th><p>File name</p></th>
                                    <th><p>IMPS_transaction_error_details_account-900192_xls.zip</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Type</p></th>
                                    <th><p>ZIP</p></th>
                                </tr>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>e9662b3b81849d7afaf55b328b31d3f8</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Size</p></th>
                                    <th><p>35.67 KB (36529 bytes)</p></th>
                                </tr>
                            </Table>
                            <p>Downloaded Zip File from GitHub</p>
                            <img src='/images/blog-images/sample33.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 6: Extracting the ZIP file revealed a JavaScript (JS) file</p>
                            <Table responsive>
                                <tr>
                                    <th><p>File name</p></th>
                                    <th><p>IMPS_transaction_error_details_account-900192_xls.js</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Type</p></th>
                                    <th><p>JavaScript</p></th>
                                </tr>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>9de4b9ce93851332d7f282449e78d3d1</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Size</p></th>
                                    <th><p>200.31 KB (205117 bytes)</p></th>
                                </tr>
                            </Table>
                            <p>JS File Extracted from Zip</p>
                            <p>We opened the JavaScript (JS) file named “function.js” in Visual Studio (VS) to analyze its source code, the obfuscated nature of the code makes it difficult to determine its purpose definitively.</p>
                            <img src='/images/blog-images/sample34.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '75%' : '100%' }} />
                            <p>Figure 7: JS File source code</p>
                            <p>Upon closer inspection and removal of the obfuscation techniques, the code reveals an array containing a list of string values. Essentially, the code acts as a container, holding a collection of text data within the program. These strings could potentially be used for various purposes depending on the script’s overall functionality strings suggesting the code might be manipulating data or variables.</p>
                            <img src='/images/blog-images/sample35.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 8: JS file upon de-obfuscation</p>
                            <p>The code also indicates the presence of functions that might define control program flow. These functions accept the names of other functions as arguments, a technique known as encapsulation. Encapsulation involves concealing code within another code segment, making it harder to detect and analyze, thereby enhancing the malware’s stealth capabilities.</p>
                            <img src='/images/blog-images/sample36.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '45%' : '100%' }} />
                            <p>Figure 9: Encapsulation technique</p>
                            <p>Each of these functions conceals the string characters within an array of numbers. The characters embedded in these arrays are subsequently concatenated to produce the required string.</p>
                            <img src='/images/blog-images/sample37.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '40%' : '100%' }} />
                            <p>Figure 10: Functions which hides the string characters between the array of numbers</p>
                            <p>The image below shows an obfuscated URL embedded within lines of what seems to be encoded or manipulated text. The de-obfuscation process involves identifying patterns where parts of the URLs (http, https, ://, etc.) are visible amidst the text.</p>
                            <p>Steps to de-obfuscate this:</p>
                            <ul>
                                <li><p>Identify URL Patterns: Notice the segments of URLs (http, https, ://) scattered across different lines.</p></li>
                                <li><p>Extract URL Segments: Collect the visible segments of URLs.</p></li>
                                <li><p>Reconstruct URLs: Piece together the segments to form complete URLs.</p></li>
                            </ul>
                            <img src='/images/blog-images/sample38.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '60%' : '100%' }} />
                            <p>Figure 11: Letters hiding between numbers and concatenation of these letters</p>
                            <p>De-obfuscation in this context involves recognizing and reconstructing strings related to scripting commands, file paths, and network communication, all of which are interspersed within extraneous data to obscure their intent.</p>
                            <img src='/images/blog-images/sample39.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '75%' : '100%' }} />
                            <p>Figure 12: Other examples of letters hiding between numbers and concatenation of these letters</p>
                            <p>Running the JS file initiates a download of a PDF (likely a decoy to distract the user).</p>
                            <img src='/images/blog-images/sample40.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '85%' : '100%' }} />
                            <p>Figure 13: JS File Execution downloaded a PDF file</p>
                            <img src='/images/blog-images/sample41.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '65%' : '100%' }} />
                            <p>Figure 14: PDF file seemed to be benign</p>
                            <Table responsive>
                                <tr>
                                    <th><p>File name</p></th>
                                    <th><p>monetarypolicysummary_april_2024.pdf</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Type</p></th>
                                    <th><p>PDF</p></th>
                                </tr>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>fc2c9d84479e3ef90971c7757e9959e4</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Size</p></th>
                                    <th><p>35.82 KB (36676 bytes)</p></th>
                                </tr>
                            </Table>
                            <p>Downloaded PDF file from the  JS File</p>
                            <p>Simultaneously, another JS payload is downloaded and placed in the Startup folder, ensuring automatic execution on every system restart.</p>
                            <img src='/images/blog-images/sample42.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '85%' : '100%' }} />
                            <p>Figure 15: Outbound connection</p>
                            <p>The following screenshot is from our network sniffer tool, revealing the domain ddfcbb9325637bcdeff[.]mxttbszhh1[.]free[.]hr in its payload. It clearly shows that this connection was initiated by the wscript.</p>
                            <img src='/images/blog-images/sample43.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 16: Connecting to domain ddfcbb9325637bcdeff[.]mxttbszhh1[.]free[.]hr</p>
                            <Table responsive>
                                <tr>
                                    <th><p>URl for PDF</p></th>
                                    <th><p>h**ps[:]//ddfcbb9325637bcdeff[.]mxttbszhh1[.]free[.]hr/oauth//pdf/MonetaryPolicySummary_April_2024[.]pdf</p></th>
                                </tr>
                                <tr>
                                    <th><p>URl for JS</p></th>
                                    <th><p>h**ps[:]//ddfcbb9325637bcdeff[.]mxttbszhh1[.]free[.]hr/oauth/drop /MonetarySummary[.]js</p></th>
                                </tr>
                                <tr>
                                    <th><p>Sub domain of sub domain</p></th>
                                    <th><p>ddfcbb9325637bcdeff[.]mxttbszhh1[.]free[.]hr</p></th>
                                </tr>
                                <tr>
                                    <th><p>Sub Domain</p></th>
                                    <th><p>mxttbszhh1[.]free[.]hr</p></th>
                                </tr>
                                <tr>
                                    <th><p>Main Domain</p></th>
                                    <th><p>free[.]hr</p></th>
                                </tr>
                                <tr>
                                    <th><p>Domain Created On</p></th>
                                    <th><p>2018-11-24</p></th>
                                </tr>
                                <tr>
                                    <th><p>Domain Updated on</p></th>
                                    <th><p>2024-04-08</p></th>
                                </tr>
                                <tr>
                                    <th><p>Domain Expires on</p></th>
                                    <th><p>2024-11-24</p></th>
                                </tr>
                                <tr>
                                    <th><p>Domain Reputation</p></th>
                                    <th><p>Blacklisted</p></th>
                                </tr>
                                <tr>
                                    <th><p>IP addresses</p></th>
                                    <th><p>172[.]67[.]154[.]165</p></th>
                                </tr>
                                <tr>
                                    <th><p>Country of the IP</p></th>
                                    <th><p>United States of America</p></th>
                                </tr>
                                <tr>
                                    <th><p>IP reputation</p></th>
                                    <th><p>Blacklisted</p></th>
                                </tr>
                                <tr>
                                    <th><p>Proxy</p></th>
                                    <th><p>Yes</p></th>
                                </tr>
                                <tr>
                                    <th><p>Proxy type</p></th>
                                    <th><p>VPN</p></th>
                                </tr>
                                <tr>
                                    <th><p>IP involvement</p></th>
                                    <th><p>Phishing, RAT, Trojan</p></th>
                                </tr>
                            </Table>
                            <p>URL reputation of de-obfuscated links</p>
                            <img src='/images/blog-images/sample44.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }} />
                            <p>Figure 17: Another malicious JS payload silently downloaded and installed into the startup folder</p>
                            <h4>Persistence Mechanism</h4>
                            <p>Upon restart, the JS file in the Startup folder runs and copies itself to the AppData Roaming directory, a common location for user-specific data.</p>
                            <img src='/images/blog-images/sample45.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }} />
                            <p>Figure 18: Upon system restart this file copies itself to the AppData Roaming directory</p>
                            <p>It utilizes a legitimate Windows utility, Wscript, to automate its tasks.</p>
                            <img src='/images/blog-images/sample46.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }} />
                            <p>Figure 19: The JS file uses a legitimate Windows utility – Wscript for its operations</p>
                            <p>A Registry entry is created to ensure that this JS file executes automatically on every system startup.</p>
                            <img src='/images/blog-images/sample47.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '70%' : '100%' }} />
                            <p>Figure 20: Registry Entry to ensure persistence</p>
                            <h4>Infection Flow of MonetarySummary.js Malware (RAT with Worm-like behaviour)</h4>
                            <p>This analysis explores the infection flow of “MonetarySummary.js,” a malicious JavaScript file with Remote Access Trojan (RAT) functionality and worm-like behaviour. It spreads by creating copies of itself on removable drives.</p>
                            <h4>Initial Execution:</h4>
                            <ol>
                                <li><p><strong>Dropping the Script:</strong> The malware was dropped from their parent java script file that was “IMPS_transaction_error_details_account-900192_xls.js”.</p></li>
                                <img src='/images/blog-images/sample48.png' width={'100%'} height={'100%'} alt='' />
                                <p>Figure 21: Script running in the background</p>
                                <Table responsive>
                                    <tr>
                                        <th><p>File name</p></th>
                                        <th><p>MonetarySummary.js</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>File Type</p></th>
                                        <th><p>JavaScript</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>MD5</p></th>
                                        <th><p>8af1b69d823c1b6cb3a9a3102e73bf3a</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>File Size</p></th>
                                        <th><p>319.71 KB (327380 bytes)</p></th>
                                    </tr>
                                </Table>
                                <li><p><strong>Decryption:</strong>The script utilizes the same encryption mechanism as its parent script (if applicable). This makes static analysis more challenging.</p></li>
                            </ol>
                            <p>The link hxxps[:]//pastie[.]io/raw/yjuddx is legitimate.  The attacker keeps the IP address of the server to which the exfiltrated is transferred on this Pastie.io link.  This approach ensures that in case this server is detected and blocked, another IP address is updated here, and the malware picks this updated address to transfer the data to.  Additionally, since Pastie.io is a legitimate site, communications to and from it may not be blocked and the new IP can be quickly updated here.</p>
                            <img src='/images/blog-images/sample49.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '60%' : '100%' }} />
                            <img src='/images/blog-images/sample50.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '60%' : '100%' }} />
                            <p>Figure 22: Decrypted dropped JS script</p>
                            <h4>Reconnaissance Stage:</h4>
                            <ol>
                                <li><p>User Information: The script gathers user information, potentially including usernames and hostnames.</p></li>
                                <li><p>System Checks:</p></li>
                                <ul>
                                    <li><p>Antivirus Query: It attempts to identify active antivirus software, potentially to evade detection.</p></li>
                                    <li><p>Hardware Information: The script gathers information about connected hardware, which could be used for profiling the victim’s system.</p></li>
                                    <li><p>Process Information: It retrieves details about running processes, possibly to identify security software or other relevant applications.</p></li>
                                    <li><p>Geolocation: The script attempts to access geolocation information from the registry, potentially to target specific regions.</p></li>
                                    <li><p>OS Name: The script gathers OS name and their version, potentially for further attack.</p></li>
                                </ul>
                                <li><p>Self-Deletion: Based on the nation code (e.g., Japan, UK), the malware deletes itself to avoid detection in specific regions.</p></li>
                            </ol>
                            <img src='/images/blog-images/sample51.png' height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '50%' : '100%' }} />
                            <p>Figure 23: Recon stage</p>
                            <h4>Persistence and Spread:</h4>
                            <ol>
                                <li><p><strong>WMI and Worm-like behaviour:</strong> The script uses Windows Management Instrumentation (WMI) to scan for external drives connected to the infected system. WQL Query: It utilizes WMI Query Language (WQL) to identify these drives.</p></li>
                                <img src='/images/blog-images/sample52.png' width={'50%'} height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '50%' : '100%' }} />
                                <p>Figure 24: Connecting to WMI</p>
                                <img src='/images/blog-images/sample53.png' width={'50%'} height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '50%' : '100%' }} />
                                <p>Figure 25: Querying the WMI</p>
                                <li><p><strong>Copying to External Drives:</strong> Once external drives are identified, the script likely copies itself onto them using the FileSystemObject or similar methods. This worm-like behaviour allows the malware to spread to other systems that access the infected drives.</p></li>
                                <img src='/images/blog-images/sample54.png' width={'50%'} height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '50%' : '100%' }} />
                                <p>Figure 26: Malware copying itself to an external drive</p>
                                <li><p><strong>Shortcut Creation:</strong> The malware leverages the Scripting.FileSystemObject to create a shortcut (.lnk) file for every file found on the external drive and change the path of that lnk file with the malicious JS file present in that external hard disk. This could be an attempt to trick users into executing the malicious script again on another system. Apart from this attacker provided a parameter in the target path that is:</p></li>
                                <ul>
                                    <li><p>Name of file and exit sign (This information was sent to attackers’ server)</p></li>
                                </ul>
                            </ol>
                            <img src='/images/blog-images/sample55.png' width={'60%'} height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '60%' : '100%' }} />
                            <img src='/images/blog-images/sample56.png' width={'60%'} height={'100%'} alt='' style={{ width: window.innerWidth > 767 ? '60%' : '100%' }} />
                            <p>Figure 27: Making shortcut link files</p>
                            <h4>C2 Server Takedowns</h4>
                            <p>The malware can intercept keyboard input and send information to attackers by various means, including email, FTP, and HTTP (by sending data in a request).  The malware made periodic connection attempts to its C2 server for Post-infection HTTPS activity, these attempts are currently unsuccessful. This could be due to several reasons:</p>
                            <ol>
                                <li><p><strong>Server Takedown: </strong>Law enforcement or security researchers may have identified and disabled the malicious command-and-control (C2) server the script was trying to reach.</p></li>
                                <li><p><strong>Technical Issues: </strong>The C2 server might be experiencing technical difficulties, causing it to be unreachable.</p></li>
                                <li><p><strong>Domain Name Expiration: </strong> Attackers often register domains specifically for their malware campaigns. If the domain hosting the C2 server expired, the script would fail to connect.</p></li>
                            </ol>
                            <img src='/images/blog-images/sample59.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 29: C2 Server communications</p>
                            <h4>Details of the C2 Server</h4>
                            <Table responsive>
                                <tr>
                                    <th><p>IP</p></th>
                                    <th><p>45[.]88[.]91[.]57</p></th>
                                </tr>
                                <tr>
                                    <th><p>Country of the IP</p></th>
                                    <th><p>Turkiye</p></th>
                                </tr>
                                <tr>
                                    <th><p>IP reputation</p></th>
                                    <th><p>Blacklisted</p></th>
                                </tr>
                                <tr>
                                    <th><p>Proxy</p></th>
                                    <th><p>Yes</p></th>
                                </tr>
                                <tr>
                                    <th><p>Proxy type</p></th>
                                    <th><p>VPN</p></th>
                                </tr>
                                <tr>
                                    <th><p>IP involvement</p></th>
                                    <th><p>Phishing, RAT, Trojan</p></th>
                                </tr>
                            </Table>
                            <h4>Threat Intel</h4>
                            <p>Data from Shodan reveals specific groups of users compromised in this ongoing attack.</p>
                            <img src='/images/blog-images/sample60.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 30: Shodan illustrating exposed interfaces</p>
                            <img src='/images/blog-images/sample61.png' width={'100%'} height={'100%'} alt='' />
                            <p>Figure 31: Our sandbox showing attack initiated from the US</p>
                            <h4>Defending against scripting malware Windows such as JS, VBS:</h4>
                            <ul>
                                <li><p>User Awareness: Educate users about phishing tactics and the dangers of downloading unknown files.</p></li>
                                <li><p>Endpoint Security: Implement robust endpoint security solutions that can detect and block malicious scripts.</p></li>
                                <li><p>External Drive Scanning: Scan external drives before use to identify and remove potential threats.</p></li>
                                <li><p>System Updates: Regularly update operating systems, applications, and security software to patch vulnerabilities.</p></li>
                            </ul>
                            <p>By understanding the infection flow of MonetarySummary.js and implementing these preventive measures, users and organizations can significantly reduce the risk of infection.</p>
                            <h4>Conclusion</h4>
                            <p>This malicious JavaScript file, disguised as a trojan, spreads via emails containing links to malicious sites.  During its operation, the script continuously communicates with its C2 Server to relay details of the compromised system and to receive further directives from its operator(s). To maintain persistence, it also copies a file to the Windows temporary folder and adds a registry entry to make the file run at startup. Once it receives instructions, the script can implement them on the infected machine.</p>
                            <p>The script is used for collecting information related to the system and file names. This collected information is sent to the attacker by POST method. This might be a part of the attack.  Once the attacker has collected all the file names and system information, he will select the target machine and in his final attack, attempt to collect all the files present on it.</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Case Study: JavaScript Malware Targeting Banking Sector'  , url: 'https://sequretek.upriselabs.io/resources/blog/hidden-threat-javascript-malware'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title={'Case Study of a JavaScript Malware with Worm-like Behaviour'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
