// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs9() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Akira Ransomware: A Detailed Analysis of Windows and Linux Threat</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Discover how Akira ransomware targets Windows and Linux systems, stealing and encrypting data. Learn about its impact across various industries and the double ransom strategy forcing victims to pay for recovery." />
                <meta name="keywords" content="ransomware analysis, malware threat analysis, ransomware data encryption" />
                <meta property="og:title" content="Akira Ransomware: A Detailed Analysis of Windows and Linux Threat" />
                <meta property="og:description" content="Discover how Akira ransomware targets Windows and Linux systems, stealing and encrypting data. Learn about its impact across various industries and the double ransom strategy forcing victims to pay for recovery." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Akira Ransomware Analysis</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Akira Ransomware Analysis</h3>
                        <p>10-AUG-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Akira ransomware targets both Windows and Linux devices. It steals and encrypts data, forcing victims to pay a double ransom for decryption and recovery.</p>
                            <p>Multiple victims of the Akira ransomware have already been targeted, primarily in the United States, and the perpetrators have published information, including their most recent data leak, on an active Akira ransomware leak site.</p>
                            <p>Akira ransomware emerged in April 2023 and has already impacted over 15 publicly disclosed victims, with the majority located in the United States. These victims represent a variety of industries, such as BFSI, Construction, Education, Healthcare, banking, finance, construction and Manufacturing.</p>
                            <p>Upon execution, activity of the malware is as follows: –</p>
                            <ul>
                                <li><p>Creates a mutex for multithreading.</p></li>
                                <li><p>It deletes the shadow copy of the victim’s machine.</p></li>
                                <li><p>Use two or more algorithms for encryption.</p></li>
                                <li><p>Targets to network drive path.</p></li>
                                <li><p>Adds extension to encrypted files “*. akira”.</p></li>
                            </ul>
                            <h4>File Details</h4>
                            <h4><u>Windows</u></h4>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>0885b3153e61caa56117770247be0444</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>941d001e2974c9762249b93496b96250211f6e0f</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>6cadab96185dbe6f3a7b95cf2f97d6ac395785607baa6ed7bf363deeb59cc360</p></th>
                                </tr>
                                <tr>
                                    <th><p>File type</p></th>
                                    <th><p>Win64 Executable</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target Machine</p></th>
                                    <th><p>x64</p></th>
                                </tr>
                                <tr>
                                    <th><p>File size</p></th>
                                    <th><p>576.00 KB (589824 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>Compiler</p></th>
                                    <th><p>Microsoft Visual C/C++ (2022+) </p></th>
                                </tr>
                                <tr>
                                    <th><p>Signature</p></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                            </Table>
                            <h4><u>Linux</u></h4>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>302f76897e4e5c8c98a52a38c4c98443</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>9180ea8ba0cdfe0a769089977ed8396a68761b40</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>1d3b5c650533d13c81e325972a912e3ff8776e36e18bca966dae50735f8ab296</p></th>
                                </tr>
                                <tr>
                                    <th><p>File type</p></th>
                                    <th><p>ELF</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target Machine</p></th>
                                    <th><p>ELF64</p></th>
                                </tr>
                                <tr>
                                    <th><p>File size</p></th>
                                    <th><p>3.79 MB (3970960 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>Compiler</p></th>
                                    <th><p>NA</p></th>
                                </tr>
                                <tr>
                                    <th><p>Signature</p></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                            </Table>
                            <h4>Static Analysis</h4>
                            <p><u>Sequretek Malware Analysis</u> and Research Team (SMART) analyzed a sample of the malware to determine the infection methodology for both the OS (Linux and Windows).</p>
                            <p>Windows version of the malware is related to <strong>a console-based 64-bit executable written in Microsoft Visual C/C++ compiler</strong> (Figure 1).</p>
                            <img src='/images/blog-images/sample63.png' height={318} alt='' style={{ width: window.innerWidth > 767 ? '85%' : '100%' }} />
                            <p>Figure 1: – Windows console-based 64-bit executable</p>
                            <p>The Linux sample is related to 64-bit Executable and Linkable Format (Figure 2).</p>
                            <img src='/images/blog-images/sample64.png' alt='' style={{ width: window.innerWidth > 767 ? '75%' : '100%' }} />
                            <p>Figure 2:- Linux File Overview</p>
                            <ul>
                                <li><p>The Windows version of the malware creates mutex (Figure 3) to configure some tasks such as –</p></li>
                                <ul>
                                    <li><p>Encryption path (Path of files/folder to be encrypted).</p></li>
                                    <li><p>Encryption percent (Percentage of the files to be encrypted).</p></li>
                                    <li><p>Deletes shadow copy.</p></li>
                                    <img src='/images/blog-images/sample65.png' alt='' style={{ width: window.innerWidth > 767 ? '75%' : '100%' }} />
                                    <p>Figure 3:- Create Mutex</p>
                                    <li><p>The Linux version used command line parameter (Figure 4) to configure some tasks such as -.</p></li>
                                    <ul>
                                        <li><p>“-p” / “–encryption_path” – Path of files/folder to be encrypted.</p></li>
                                        <li><p>“-s” / “–share_file” – Path of the shared network drive to be encrypted.</p></li>
                                        <li><p>“-n” / “–encryption_percent” – Percentage of the files to be encrypted.</p></li>
                                        <li><p>“-fork” – Creating a child process for encryption.</p></li>
                                        <img src='/images/blog-images/sample66.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                        <p>Figure 4: – Command Line Parameter used by Akira Ransomware</p>
                                        <li><p>The ransomware executes a PowerShell command to delete the shadow copy, thereby preventing the recovery of the system (Figure 5).</p></li>
                                        <img src='/images/blog-images/sample67.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                        <p>Figure 5: Delete the shadow copy.</p>
                                        <li><p>In order to determine the number of logical drives on the system, malware uses API function “GetLogicalDriveStrings()” (Figure 6).</p></li>
                                        <img src='/images/blog-images/sample68.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                        <p>Figure 6: Get logical drive info by using “GetLogicalDriveStrings()”.API</p>
                                        <li><p>It also searches for files and directories to encrypt using the API functions “FindFirstFileW()” and “FindNextFileW()” and excludes the below file extensions and file/folder names from encryption.</p></li>
                                    </ul>
                                    <h4><u>Windows</u></h4>
                                    <Table responsive>
                                        <tr>
                                            <th><h4>Exclude file extension</h4></th>
                                            <th><h4>Exclude folder</h4></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.exe</p></th>
                                            <th><p>winnt</p></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.dll</p></th>
                                            <th><p>temp</p></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.sys</p></th>
                                            <th><p>thumb</p></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.msi</p></th>
                                            <th><p>$Recycle.Bin</p></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.lnk</p></th>
                                            <th><p>System volume</p></th>
                                        </tr>
                                        <tr>
                                            <th><p>*.akira</p></th>
                                            <th><p>Information boot</p></th>
                                        </tr>
                                        <tr>
                                            <th><p></p></th>
                                            <th><p>ProgramData</p></th>
                                        </tr>
                                    </Table>
                                    <h4><u>Linux</u></h4>
                                    <p>Included file extensions for encryption in Linux environment (Figure 7).</p>
                                    <img src='/images/blog-images/sample69.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                    <p>Figure 7: – File extensions targeted by Akira in Linux</p>
                                    <li><p>To encrypt the victim’s computer, the ransomware uses the “Microsoft Enhanced RSA and AES Cryptographic Provider” libraries.</p></li>
                                    <ul>
                                        <li><p>CryptAcquireContextW()</p></li>
                                        <li><p>CryptImportPublicKeyInfo()</p></li>
                                        <li><p>CryptGenRandom()</p></li>
                                        <li><p>CryptEncrypt()</p></li>
                                    </ul>
                                </ul>
                                <li><p>Akira ransomware uses the RSA and AES encryption algorithms for windows, and it comes with a fixed hardcoded base64 encoded public key (Figure 8).</p></li>
                                <h4><u>Windows</u></h4>
                                <img src='/images/blog-images/sample70.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 7: – File extensions targeted by Akira in Linux</p>
                                <h4><u>Linux</u></h4>
                                <ul>

                                    <li><p>The encryption algorithms used by the Linux version of the malware to encrypt the system file include the following (Figure 9):</p></li>
                                    <ul>
                                        <li><p>AES-256-CBC</p></li>
                                        <li><p>CAMELLIA-256-CBC</p></li>
                                        <li><p>DES-CBC</p></li>
                                        <li><p>IDEA-CBC</p></li>
                                    </ul>
                                </ul>
                                <img src='/images/blog-images/sample71.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 9: – Encryption Techniques used by Akira Ransomware</p>
                                <li>The Linux version is specifically designed to target VMware Esxi servers (Figure 10).</li>
                                <img src='/images/blog-images/sample72.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 10: – Targeted VMware Esxi Server</p>
                                <li>After the malware detects a VMware Esxi server, it uses the locked function (Figure 11).</li>
                                <img src='/images/blog-images/sample73.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 11: – Locked function used for Esxi Server</p>
                            </ul>
                            <h4>Base64 encoded public key (Windows & Linux):</h4>
                            <img src='/images/blog-images/sample74.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 12: Encoded Public Key</p>
                            <h4>Dynamic Analysis</h4>
                            <p>Sequretek Malware Analysts executed the sample in our sandbox environment to observe its behavior and the following activities were observed.</p>
                            <ul>
                                <li><p>The malware first executes a PowerShell command to delete shadow copy of victim PC using “Wmiobject”</p></li>
                                <img src='/images/blog-images/sample75.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 13: Akira ransomware process tree</p>
                                <li><p>After deleting the shadow copy, the ransomware uses the RSA and AES encryption to encrypt the files with extension. ”.akira” (Figure 14).</p></li>
                                <img src='/images/blog-images/sample75.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 14: Akira ransomware file encryption</p>
                                <li><p>The ransomware also creates a text file (.txt) on the victim system during the encryption process.</p></li>
                                <img src='/images/blog-images/sample76.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 15: Akira drops text file with name “akira_readme.txt”</p>
                                <li><p>Malware drops the text file as ransom note in multiple folders with the file name “akira_readme.txt”. It contains instructions for decryption (Figure 16).</p></li>
                                <img src='/images/blog-images/sample77.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 16: Akira ransom note</p>
                                <p>Akira provides instructions on how the victims can obtain their ransom payments in its ransom note. According to the Akira ransom note, the victim is offered “support” by the company to recover the encrypted files.</p>
                                <p>Ransom note also includes a link to a TOR website where victims can communicate with the attackers and attempt to retrieve their data (Figure 17).</p>
                                <img src='/images/blog-images/sample78.png' alt='' style={{ width: window.innerWidth > 767 ? '100%' : '100%' }} />
                                <p>Figure 17: File recovery instructions</p>
                                <li><p>Akira maintains a leak site on the dark web to enable its victims to contact them for data retrieval as well as to advertise their activities in case the victims fail to pay up (Figures 18 & 19).</p></li>
                                <img src='/images/blog-images/sample79.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 18: Akira’s dark web leak site</p>
                                <img src='/images/blog-images/sample80.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 19: News about Akira ransomware</p>
                            </ul>
                            <h4>Detection Indicators</h4>
                            <ul>
                                <li><p>Hash values of the samples analyzed:</p></li>
                                <p>0885b3153e61caa56117770247be0444 (Windows)</p>
                                <p>302f76897e4e5c8c98a52a38c4c98443 (Linux )</p>
                                <li><p>Ransomware payload includes Tor browser onion links to communicate with the attackers.</p></li>
                                <li><p>The malware encrypted system files and created a ransom note for payment instructions.</p></li>
                            </ul>
                            <h4>MITRE ATT&CK Tactics and Techniques</h4>
                            <p><strong>Execution</strong>-TA0002</p>
                            <ul>
                                <li><p>Windows Management Instrumentation -T1047</p></li>
                                <li><p>Creates processes via WMI</p></li>
                                <li><p>Connect to WMI namespace via WbemLocator</p></li>
                                <li><p>Queries process information (via WMI, Win32_Process)</p></li>
                                <li><p>Delete volume shadow copied</p></li>
                            </ul>
                            <p><strong>Defense Evasion</strong>-TA0005</p>
                            <ul>
                                <li><p>Obfuscated Files or Information- T1027</p></li>
                                <li><p>Encode data using Base64</p></li>
                                <li><p>Create new key via CryptAcquireContext</p></li>
                                <li><p>Encrypt or decrypt via WinCrypt</p></li>
                            </ul>
                            <p><strong>Credential Access</strong>-TA0006</p>
                            <ul>
                                <li><p>OS Credential Dumping-T1003</p></li>
                                <li><p>Tries to harvest and steal browser information (history, passwords, etc)</p></li>
                            </ul>
                            <p><strong>Virtualization/Sandbox Evasion </strong>-T1497</p>
                            <ul>
                                <li><p>May sleep (evasive loops) to hinder dynamic analysis</p></li>
                                <li><p>Contains long sleeps (&gt;= 3 min)</p></li>
                                <li><p>Contains medium sleeps (&lt;= 30s)</p></li>
                            </ul>
                            <p><strong>Discovery</strong>-TA0007</p>
                            <ul>
                                <li><p>System Information Discovery-T1082</p></li>
                                <li><p>Get disk information</p></li>
                                <li><p>Queries the volume information (name, serial number etc) of a device</p></li>
                                <li><p>Queries process information (via WMI, Win32_Process)</p></li>
                                <li><p>Queries the cryptographic machine GUID</p></li>
                                <li><p>Reads software policies</p></li>
                            </ul>
                            <p><strong>Impact</strong>-TA0034</p>
                            <ul>
                                <li><p>Data Encrypted for Impact -T1486</p></li>
                                <li><p>Writes a notice file (html or txt) to demand a ransom</p></li>
                            </ul>
                            <h4>Conclusion</h4>
                            <p>Akira ransomware has been observed since at in April 2023.</p>
                            <p>Akira ransomware is a newly discovered strain that has primarily affected American and Canadian victims. It infects a device and steals/encrypts sensitive data with their attack strategy.</p>
                            <p>A formerly Windows-centric ransomware group, Akira is now giving more attention to the Linux platforms. As we have seen, Akira ransomware evidently also targets the VMWare Esxi Server.</p>
                            <p>This ransomware group targets businesses and demands a significant amount of money in exchange for the decryption key. In the event that their demands are not met, they threaten to publish the data on their dark web blog.</p>
                            <h4><u>Best Practices for Risk Reduction against Ransomware Attacks </u></h4>
                            <ul>
                                <li><p>Backup your data: Ensure that your organization’s data is regularly backed up and the backups are stored in a separate location. This will help you recover your data if you fall victim to any ransomware attack. </p></li>
                                <li><p>Keep software up to date: Ensure that your organization’s operating systems, software applications and other devices are updated with the latest security patches and updates. Regularly updating your software will help prevent known vulnerabilities from being exploited. </p></li>
                                <li><p>Implement a strong password policy at your organization: Ensure that your employees use strong passwords and two-factor authentication to access organization’s systems and data. Weak passwords can be easily targeted by ransomware attackers. </p></li>
                                <li><p>Regularly train employees on security best practices: Provide regular training to your employees on how to identify and avoid suspicious emails, links, and attachments that could be potential ransomware vectors. </p></li>
                                <li><p>Limit access: Restrict access to sensitive organization’s data to only those who need it. This will help prevent ransomware from spreading through your organization’s network. </p></li>
                                <li><p>Implement network segmentation: Segment your organization’s network into smaller subnetworks to prevent the spread of ransomware in the event of an attack. </p></li>
                                <li><p>Use security tools: Implement security tools such as firewalls, antivirus / EDR, intrusion detection and prevention systems, identity governance and administration (IGA) and security monitoring / extended detection & response (XDR) solutions to monitor, protect, respond and remediate against malicious activities / incidents at your organization.</p></li>
                                <li><p>Have a plan: Develop a plan for responding to ransomware attacks. This should include identifying key personnel and responsibilities, having a backup and recovery plan, and having a communication plan in place to inform stakeholders of the situation.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Akira Ransomware: A Detailed Analysis of Windows and Linux Threat'  , url: 'https://sequretek.upriselabs.io/resources/blog/akira-ransomware-tactics'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title='Akira Ransomware Analysis'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
