// ############################ Require packages here ############################
import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player';
import Aos from 'aos';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './product.scss';
import ScheduleForm from '../scheduleForm';
import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';

const PerceptIdentitycards = [
    { image: '/images/it-computer.svg', title: 'Comprehensive Endpoint Security', description: 'End-to-end security against advanced and targeted attacks using integrated technologies within every single endpoint with on-agent AI.' },
    { image: '/images/network-analytic.svg', title: 'Stops Lateral Movement', description: 'Blocks unauthorized network movements from compromised endpoints by behavior & reputation scoring, device control, and allow/deny listing.' },
    { image: '/images/shield-virus.svg', title: 'Protection Across All Attack Vectors', description: ' Stops cryptojackers, keyloggers, ransomware, malware, process injections, APTs, privilege escalations, and zero-day attacks.' },
    { image: '/images/shieldcheck.svg', title: 'Defends Against Various Attack Profiles', description: 'Protects from lone wolves, organized crime, corporate or nation state-sponsored threat actors and their attack vectors.' },
    { image: '/images/magnifying-glass-eye.svg', title: 'Real-Time Detection & Protection', description: 'Combines next-gen antivirus and EDR with enhanced AI models for different payloads offering complete and real-time protection.' },
    { image: '/images/overview.svg', title: 'Real-Time Prevention', description: 'Features like static AI, threat intel, pre-execution and reputational analysis, rogue device detection, help real-time prevention.' },
    { image: '/images/circle-exclamation-check.svg', title: 'Rapid Response and Remediation', description: ' 360° view, mitigation, actionable threat intel, threat hunting & triaging, patching, RCA, and forensics enable fast remediation.' },
    { image: '/images/api.svg', title: 'Fast Deployment', description: 'Preconfigured with granular groups & policies for centralized and distributed deployment, making it operational quickly.' },
    { image: '/images/process.svg', title: 'Simplified & Proactive Operations', description: ' Streamlines on-prem and remote security operations across endpoints, servers, and cloud environments with a single console.' },
    { image: '/images/shieldsecurity.svg', title: 'Enhanced Detection', description: 'AI-based algorithms and behavior analyses to detect internal and external threats, even when devices are offline and/or not updated.' },
    { image: '/images/settings-sliders.svg', title: 'Application Control', description: 'Trusted path/directory/certificate-based allow/deny listing to prevent access to harmful apps, minimizing risks from them.' },
    { image: '/images/sensor-alert.svg', title: 'Proactive Vulnerability Management', description: 'Real-time scanning, policy-based auto-fixes, and detailed inventory views to identify and mitigate vulnerabilities proactively.' },
    { image: '/images/dashboard-monitor.svg', title: 'Advanced Analysis', description: 'Facilitates advanced threat hunting and intelligence with rich telemetry data and extensive security workbench analytics with XDR.' },
    { image: '/images/memo-circle-check.svg', title: 'Out-of-the-Box Reports', description: 'Compliance, audit-ready, patch, IOC/IOA, vulnerability, scan, rogue device access and other customized reports for enhanced governance.' },



]


const Graphitems = [
    { image: '/images/shieldplus.svg', title: 'Static, Dynamic & Behavior AI' },
    { image: '/images/shieldplus.svg', title: 'Device Blocking' },
    { image: '/images/shieldplus.svg', title: 'Application Whitelisting & Blacklisting' },
    { image: '/images/shieldplus.svg', title: 'Vulnerability Assessment & Management' },
    { image: '/images/shieldplus.svg', title: 'NGAV & EDR' },
    { image: '/images/shieldplus.svg', title: 'Containment' },
    { image: '/images/shieldplus.svg', title: 'Threat Mitigation' },
    { image: '/images/shieldplus.svg', title: 'Threat Hunting and Triaging' },
    { image: '/images/shieldplus.svg', title: 'Patching' },
    { image: '/images/shieldplus.svg', title: 'Actionable Threat Intel' },
    { image: '/images/shieldplus.svg', title: 'RCA & Forensics' },
    { image: '/images/shieldplus.svg', title: 'Granular Inventory View' },
    { image: '/images/shieldplus.svg', title: 'Unified On-Agent AI' },
    { image: '/images/shieldplus.svg', title: 'EDR Management Platform' },
    { image: '/images/shieldplus.svg', title: 'EDR Administration, Management and Reporting' },
    { image: '/images/shieldplus.svg', title: 'EDR Telemetry' },
    { image: '/images/shieldplus.svg', title: 'Compliance and Audit-Ready Reports' },
    { image: '/images/shieldplus.svg', title: 'In-Memory Protection' },
    { image: '/images/shieldplus.svg', title: 'Endpoint Asset Inventory' },
]

const PerceptEdr = () => {

    const active = 'perceptEdr';
    const [secNav, setSecNav] = useState(false);
    const [tabActive, setTabActive] = useState('vectors');

    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Percept-about')?.offsetTop;
        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }


    const toggleNav = () => {
        setSecNav(!secNav)
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setActive = (val) => {
        window.scrollTo(0, 0);
        if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'products') {
            window.innerWidth > 767 ? window.scrollTo(0, 4800) : window.scrollTo(0, 3800);
        } else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }


    const downloadDatasheet = async () => {
        const res = await dispatch(getOneResource(['66e2ec29c514ec4d6801f9bf']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/datasheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }


    return (
        <div className='seq-Percept-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Endpoint Security with Percept EDR | AI-powered protection</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Secure your endpoints against advanced threats with Percept EDR. Benefit from real-time prevention, detection, and protection." />
                <meta name="keywords" content="Endpoint Detection and Response, EDR, End point Security" />
                <meta property="og:title" content="Sequretek | Endpoint Security with Percept EDR | AI-powered protection" />
                <meta property="og:description" content="Secure your endpoints against advanced threats with Percept EDR. Benefit from real-time prevention, detection, and protection." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%),url('/images/bg-img26.png')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero d-flex justify-content-center'>
                        <div className='seq-Percept-hero-identity' data-aos="fade-up">
                            <h3 className='seq-Percept-hero-identity-head d-flex justify-content-center pb-0'>Secure Your Endpoints, <br />Stop Breaches in Their Tracks</h3>
                            <p className='seq-Percept-hero-identity-text'>AI-powered endpoint security for your devices, servers and cloud environments</p>
                            {window.innerWidth > 767 ?
                                <div className='d-flex justify-content-center'>
                                    <button onClick={() => setActive('products')}><strong>Get Percept EDR</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div> :
                                <div>
                                    <button onClick={() => setActive('products')}><strong>Get Percept EDR</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className='seq-Percept-tab-pages'>
                {/* section 2 SECOND NAVBAR*/}
                {window.innerWidth > 767 ?
                    <div>
                        <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                            <div onClick={() => setActive('perceptplatform')} className={active === 'perceptplatform' ? 'seq-tab active' : 'seq-tab'}>
                                About Percept Platform
                            </div>
                            <div onClick={() => setActive('perceptXdr')} className={active === 'perceptXdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept XDR & NG SIEM
                            </div>
                            <div onClick={() => setActive('PerceptIdentity')} className={active === 'PerceptIdentity' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Identity
                            </div>
                            <div onClick={() => setActive('perceptEdr')} className={active === 'perceptEdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept EDR
                            </div>
                            <div onClick={() => setActive('perceptManager')} className={active === 'perceptManager' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Compliance Manager
                            </div>
                        </div>
                    </div>
                    :
                    <div className='secondary-header'>
                        <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                            <div className="seq-active d-flex align-items-center">
                                <h4>Percept EDR</h4>
                            </div>
                            <div className="dropdown d-flex" onClick={toggleNav} >
                                <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" />
                            </div>
                        </div>
                        <nav className={`menu ${secNav ? 'open' : ''}`}>
                            <ul className='seq-drop-items'>
                                <li onClick={() => setActive('perceptXdr')}>Percept XDR and NGSIEM</li>
                                <li onClick={() => setActive('PerceptIdentity')}>Percept Identity</li>
                                <li onClick={() => setActive('perceptplatform')}>About Percept Platform</li>
                                <li onClick={() => setActive('perceptManager')}>Percept Compliance Manager</li>
                            </ul>
                        </nav>
                    </div>
                }
                {/* section 3 PLAYER */}
                <div className='seq-Percept-about'>
                    {/* PLAYER */}
                    <div className='container pe-0 ps-0'>
                        <div className='d-flex justify-content-center seq-Percept-player' >
                            <ReactPlayer url='https://youtu.be/mxMcwRazQuo' className='seq-Percept-about-player' width='1128px' height='595px' />
                        </div>
                    </div>
                    {/* Img Session */}
                    <div className='seq-benifits-img'>
                        <div className='container pe-0 ps-0'>
                            <div className='d-flex justify-content-center'><h4>PERCEPT EDR</h4></div>
                            {window.innerWidth > 767 ?
                                <img className='seq-percept-main-img' src='/images/EDR-Diag.svg' alt='' />
                                :
                                <div>
                                    {/* accordion one */}
                                    <div className='seq-identity-accordionone'>
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Real-time Prevent </div>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Static AI</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Whitelisting & blacklisting</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Vulnerability assessment</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Device blocking</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Threat intel</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Next-gen antivirus</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Real-time Detect and Protect</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Dynamic AI</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Behavior AI</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Containment </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>File-less attacks</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>File-based attacks</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Rapid Response and Remediation</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>360 View </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Mitigation </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Actionable threat intel </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Threat hunting</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Threat triaging </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>Patching</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' /></div>&nbsp;&nbsp;
                                                            <p>RCA & forensics</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* GIF */}
                                    <div className='seq-identity-gif d-flex'>
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                        <img src='/images/Uparrow-img.gif' alt='' />
                                    </div>
                                    {/* TAB SWITCH */}
                                    <div className='seq-edr-mobile-tabs'>
                                        <div className='d-flex justify-content-between seq-edr-mobile-tabs-head'>
                                            <h5 onClick={() => setTabActive('vectors')} className={tabActive === 'vectors' ? 'active' : ''}>Attack Vectors</h5>
                                            <h5 onClick={() => setTabActive('profiles')} className={tabActive === 'profiles' ? 'active' : ''}>Attacker Profiles</h5>
                                        </div>
                                        {tabActive === 'vectors' ?
                                            <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '15px', padding: '20px 0' }}>
                                                <div className='d-flex'>
                                                    <div style={{ width: '120px', marginLeft: '15px' }}>
                                                        <p>Crypto jackers</p>
                                                        <p>Key loggers</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ width: '120px', marginLeft: '15px' }}>
                                                        <p>Ransomware, Malware</p>
                                                        <p>Process mitigation </p>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ width: '200px', marginLeft: '20px' }}>
                                                        <p>APT, Lateral movements,</p>
                                                        <p>Privilege escalations, Zero-day</p>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '15px', padding: '20px 0' }}>
                                                <div className='d-flex'>
                                                    <div style={{ width: '120px', marginLeft: '15px' }}>
                                                        <p>Lone wolf attackers</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ width: '150px', marginLeft: '15px' }}>
                                                        <p>Organized crime threat actors/attack groups</p>
                                                    </div>
                                                </div>
                                                <div className='d-flex'>
                                                    <div style={{ width: '150px', marginLeft: '15px' }}>
                                                        <p>Corporate/ nation-sponsored threat actors</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div >
                            }
                        </div>
                    </div>
                    {/* section 4 BENEFITS & FEATURES */}
                    <div className='seq-Percept-benefits'>
                        {/* section 4-1 BENEFITS */}
                        <div className='container pe-0 ps-0'>
                            <p style={{ color: '#2076CD', fontSize: '16px' }}>BENEFITS</p>
                            <div className='row mx-0' style={{ marginBottom: '18px' }}>
                                <div className='col-12 col-md-6 col-sm-12 px-0'>
                                    <h3>How Evolved are Your Endpoint Security Solutions in an Ever-Evolving Threat Landscape?</h3>
                                </div>
                                <div className='col-12 col-md-6 col-sm-12 px-0'>
                                    <p>Advanced threats stalk enterprise endpoint devices constantly. Threat actors can compromise vulnerable endpoints and bring networks down in seconds. Cloud-native Percept EDR is a comprehensive, cross-platform technology to detect and protect against advanced threats. Our on-agent AI ensures offline protection for devices.</p>
                                </div>
                            </div>

                            <div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row me-0 seq-bentfits-row-card seq-benefits-cards'>
                                            {
                                                PerceptIdentitycards?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-Perceptmanager-card' >
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap'>
                                            {
                                                PerceptIdentitycards?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col-md-3'>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                        {/* section 4-2 FEATURES */}
                        <div className='container pe-0 ps-0'>
                            <div className='seq-Percept-graph' >
                                <p data-aos="fade-up" className='d-flex justify-content-center align-items-center'>FEATURES</p>
                                <h3 data-aos="fade-up" className='d-flex justify-content-center align-items-center'>Percept EDR Makes Your Security Teams More Effective by Reducing Their Workloads</h3>
                                <img data-aos="fade-up" className='seq-Percept-graph-img1' src='/images/graph-edr.png' alt='' />
                                <div>
                                </div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='seq-Percept-graph-extra col-md-2' >
                                                            <div>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap '>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='d-flex justify-content-center align-items-center'>
                                                            <div className='seq-Percept-graph-extra' style={{ gap: '10px' }}>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' style={{ width: '24px', height: '24px', marginBottom: '12px' }} />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* section 5 TESTIMONIAL SLIDER */}
                <div className="seq-quotes">
                    <div className='gredient'> </div>
                    <TestimonialSlider
                        page={'product-EDR'}
                    />
                </div>
                {/* section 6 SCHEDULE DEMO FORM */}
                <div className='seq-forms'>
                    <div className='container'>
                        <div className="insurance-partseven" data-aos="fade-up">
                            {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading'>Cybersecurity starts with endpoint protection. Get started - schedule a demo of Percept EDR.</h2> : null}
                            <ScheduleForm
                                bg={'/images/bg-img22.svg'}
                                mobilebg={'/images/light-umbrella.svg'}
                                page='percept-edr'
                                text='Most cyberattacks are carried out in a targeted manner, with enterprise endpoint devices being the most vulnerable. Secure your endpoints ensuring that your critical information remains confidential and protected.'
                            />
                        </div>
                    </div>
                </div>
                {/* section 7 RELATED RESOURCES */}
                <div className='resources'>
                    <div className='container ps-0'>
                        <div className='row '>
                            <h4>Related resources</h4>
                            <div className='col-md-12'>
                                <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                    <RelatedResources
                                        page='perceptedr'
                                        type=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Resource Center</button></div>}
            </div>
        </div>
    )
}

export default PerceptEdr
