// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs11() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Technical Analysis of a Malicious Android App</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Technical Analysis of a Malicious Android App</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Technical Analysis of a Malicious Android App</h3>
                        <p>03-OCT-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>APT36, also known as Earth Karkaddan and Transparent Tribe, is a politically motivated advanced persistent threat (APT) group, has a reputation of targeting Indian military and diplomatic resources. APT36, is a suspected Pakistan-based Threat Actors (TA) group that gains access to the victims’ phones using social engineering techniques to deploy an information stealer.</p>
                            <p><u>Sequretek Malware Analysis Team</u> recently analysed a malicious Android app that has been attributed to a campaign by APT36.</p>
                            <h4>Technical Analysis</h4>
                            <p>The initial vector being dangerous applications hosted on self-run websites, CapraRAT leverages social engineering to access the victims’ phones. TrendMicro has named this Android RAT as “CapraRat”— which is possibly a modified version of an open-source RAT called AndroRAT.</p>
                            <p>Using spear-phishing emails and links as its arrival vector, CapraRAT comes in three applications posing as YouTube application.</p>
                            <p>All these three apps share the same source code and therefore perform same activities.</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Sr.no.</p></th>
                                    <th><p>Name</p></th>
                                    <th><p>MD5</p></th>
                                </tr>
                                <tr>
                                    <th><p>1</p></th>
                                    <th><p>YouTube_052647.apk</p></th>
                                    <th><p>495c4c77c9dceda458930c33b8391f27</p></th>
                                </tr>
                                <tr>
                                    <th><p>2</p></th>
                                    <th><p>yt.apk</p></th>
                                    <th><p>fc54078c5ae26d856109d306c37909ae</p></th>
                                </tr>
                                <tr>
                                    <th><p>3</p></th>
                                    <th><p>Piya Sharma.apk</p></th>
                                    <th><p>c350c3cd119e517477b35e6830947531</p></th>
                                </tr>
                            </Table>
                            <h4>Request exemptions from battery optimizations</h4>
                            <p>Starting with the Main Activity that is the first activity that the app performs and displays on its UI on opening the app, it checks whether its package name is on the device’s power allowlist. This function returns true if the device is in Doze/Idle mode. Should be called before checking the network connection because the ConnectionManager may report the device is connected when it isn’t during Idle mode. The apps places itself on the allowlist through the settings UI invoked by Settings.ACTION_IGNORE_BATTERY_OPTIMIZATION_SETTINGS. Adding an app to the allowlist or ignoring battery optimizations on Android can help ensure that the app continues to run in the background, even when the device is in a low-power state. This can be useful for the app to run background services or notifications to function correctly. This behavior can have a significant impact on battery life.</p>
                            <img src='/images/blog-images/sample85.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 1: App adds itself to allowlist</p>
                            <h4>Implementing auto-start functionality</h4>
                            <p>Again in the Main Activity, the app checks for phone brand and then registers itself for auto start. The Android auto-start management is used to ease you in starting your favorite applications on your device through one click. The application allows you to pick one app that’ll be launched whenever you reboot your device. This is how the app persists on the device even on reboot.</p>
                            <img src='/images/blog-images/sample86.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 2: App registers itself for auto-start</p>
                            <h4>Impersonating as YouTube app</h4>
                            <p>As the apps pose themselves as YouTube app, upon opening the app loads the YouTube website on a web browser.</p>
                            <img src='/images/blog-images/sample87.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 3: App opens YouTube in browser</p>
                            <h4>Using AlarmManager to schedule background tasks at specified intervals</h4>
                            <p>The application starts a service using Alarm manager. When a button is clicked service should start in a particular time from current time. To maintain persistence in an Android app using AlarmManager, the app can schedule periodic tasks or services to run at specified intervals or times.</p>
                            <img src='/images/blog-images/sample88.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 4: App maintains persistence using Alarm Manager</p>
                            <h4>Playing a video file as an APK</h4>
                            <p>The app performs some unconventional actions. For example, it appears to save a video file (MP4) as a resource and then launch it as a separate APK. The video will continue to play in the background, even if you’re not actively using the app, you turn off the screen or switch to another app while the video is playing.</p>
                            <img src='/images/blog-images/sample89.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 5: App launches video files as apk to persist</p>
                            <h4>Controlling the app’s visibility within the system</h4>
                            <p>These two methods, showApp() and hideApp(), manipulate the app’s visibility by enabling or disabling its main activity component using the PackageManager. The choice between showing or hiding the app is based on the current value of the setting.mehiden flag.</p>
                            <img src='/images/blog-images/sample90.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 6: The provided code includes two methods which are used to show or hide an Android app</p>
                            <h4>Receiving remote commands</h4>
                            <p>The provided code defines a method named getCommand(DataInputStream din) that reads a command as a string from an input stream (din). It appears to be designed to receive and process commands sent over a network connection. It assumes that commands are sent in a specific binary format and then converted into strings for further processing.</p>
                            <img src='/images/blog-images/sample91.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7: This code is used for parsing and processing commands received from an input stream.</p>
                            <h4>Rooting the device and running root commands</h4>
                            <p>The provided code defines a method named runAsRoot(String[] cmds, boolean shouldExit) that is used to run shell commands as the root user on an Android device. This method allows you to execute a series of shell commands within a root shell. The device must be rooted for this method to work, as it relies on the “su” command to gain root access.</p>
                            <img src='/images/blog-images/sample92.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7: This code is used for parsing and processing commands received from an input stream.</p>
                            <h4>Rooting the device and running root commands</h4>
                            <p>The provided code defines a method named runAsRoot(String[] cmds, boolean shouldExit) that is used to run shell commands as the root user on an Android device. This method allows you to execute a series of shell commands within a root shell. The device must be rooted for this method to work, as it relies on the “su” command to gain root access.</p>
                            <img src='/images/blog-images/sample93.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 8: This method allows you to run a series of shell commands with root privileges on an Android device.</p>
                            <h4>Data theft by exfiltration</h4>
                            <p>The app steals information sets its window background to a transparent color (0) using a ColorDrawable or by running it as a service in the background without UI.</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Information stolen</p></th>
                                    <th><p>Description</p></th>
                                </tr>
                                <tr>
                                    <th><p>SMS messages</p></th>
                                    <th><p>The app collects the phone numbers and the message content.</p></th>
                                </tr>
                                <tr>
                                    <th><p>Audio</p></th>
                                    <th>
                                        <p>The app requests the RECORD_AUDIO permission and sends</p>
                                        <p>notifications regarding the permission status to other parts of the application. It handles</p>
                                        <p>permission responses and finishes the activity after notifying the user.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Contacts</p></th>
                                    <th>
                                        <p>The app requests the READ_CONTACTS permission and send notifications</p>
                                        <p>regarding the permission status to other parts of the application.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Call logs</p></th>
                                    <th>
                                        <p>The app requests a specific permission (READ_CALL_LOG) and sends</p>
                                        <p>notifications about the permission status to other parts of the application.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Location</p></th>
                                    <th>
                                        <p>The app requests a specific permission</p>
                                        <p>(ACCESS_FINE_LOCATION) and sends</p>
                                        <p>notifications about the permission status to other parts of the application.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Camera</p></th>
                                    <th>
                                        <p>The app requests a specific permission (CAMERA) and sends notifications</p>
                                        <p>about the permission status to other parts of the application.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Storage</p></th>
                                    <th>
                                        <p>The app requests a specific permission (PERMISSIONS_REQUEST_READ and</p>
                                        <p>PERMISSIONS_REQUEST_WRITE) and sends notifications about the permission status to other parts of the application.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Phone state</p></th>
                                    <th>
                                        <p>The app requests a specific permission (READ_PHONE_STATE) and sends notifications about the permission status to other parts of the application. This permission allows read only access to phone state, including the current cellular network information, the status of any ongoing calls, and a list of any</p>
                                        <p>PhoneAccounts registered on the device.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Screenshot(Service)</p></th>
                                    <th>
                                        <p>This service is designed to capture screenshots of the device’s screen and broadcast them to other components within the app. It manages the screen</p>
                                        <p>capture lifecycle and responds to broadcast intents to stop capturing screens.</p>
                                    </th>
                                </tr>
                            </Table>
                            <br />
                            <br />
                            <Table responsive>
                                <tr>
                                    <th><p></p></th>
                                    <th>
                                        <p>The code also includes threading to perform image processing in the background as a byte array (png). It decodes the byte array into a bitmap, scales it according to settings, compresses it as a PNG, and broadcasts it along</p>
                                        <p>with other information using an intent.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Notifications(Service)</p></th>
                                    <th>
                                        <p>The app checks whether the notification access service for the app is enabled. Notification access services allow apps to read and interact with notifications</p>
                                        <p>generated by other apps on the device.</p>
                                    </th>
                                </tr>
                            </Table>
                            <h4>Sending data over a network socket connection</h4>
                            <p>All of this information is sent as TCP data. The provided code defines a method named sendData(byte[] data, String cmd) that appears to be used for sending data over a network socket connection. This method takes a byte array (data) and a command string (cmd) as parameters and sends them over the socket connection.</p>
                            <img src='/images/blog-images/sample94.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 9: This method appears to handle the sending of data over a socket connection.</p>
                            <p>The SERVERIP variable is called to connect to the IP address. All the three apps connect to different IP addresses that are mentioned below in the IoCs.</p>
                            <img src='/images/blog-images/sample95.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 10: IP address variable</p>
                            <img src='/images/blog-images/sample96.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 11: App showing established connection</p>
                            <h4>Receiving and processing commands from C2</h4>
                            <p>On connecting to this server, the app receives commands. The provided code appears to be part of a background thread’s run method. This thread seems to be responsible for establishing a socket connection to a server, receiving and processing commands, and taking various actions based on the received commands.</p>
                            <img src='/images/blog-images/sample97.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 12: The code handles the initialization, connection, and command processing logic.</p>
                            <h4>Conclusion</h4>
                            <p>One way or another, the app finds a way to get its job done. It will handle error exceptions, set a refresh alarm to always keep the app running. Additionally, the malware maintains its persistence by adding itself to the power allowlist, registering itself for auto-start and launching video files as apk to persist.</p>
                            <p>Not only has the malware been equipped with the arsenal to steal information but it can also connect to a remote server and perform these actions. These are all the striking features of the malware that demonstrate the determination of the attackers to persist.</p>
                            <h4>Recommendations to prevent infection</h4>
                            <ul>
                                <li><p>Do not install apps from unknown sources. Use Only the Official Google Play Store to download or update</p></li>
                                <li><p>Be wary of any suspicious social engineering</p></li>
                                <li><p>Always keep your device up to date. Making sure your apps and Android itself is always using the latest version is an easy way to secure your device.</p></li>
                                <li><p>Keep your data backed</p></li>
                                <li><p>Install an antivirus</p></li>
                            </ul>
                            <h4>Indicators of Compromise (IoCs)</h4>
                            <p>CapraRAT app hashes</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Name</p></th>
                                    <th><p>MD5</p></th>
                                    <th><p>SHA1</p></th>
                                    <th><p>SHA256</p></th>
                                </tr>
                                <tr>
                                    <th><p>YouTube_052647.apk</p></th>
                                    <th><p>495c4c77c9dceda45</p>
                                        <p>8930c33b8391f27</p></th>
                                    <th><p>83412f9d757937f2719e</p>
                                        <p>bd7e5f509956ab43c3ce</p></th>
                                    <th><p>f2d43369016b6c106f07cb214afdfb9807</p>
                                        <p>b808fc5fe6fd6cf7a6405271cafdd5</p></th>
                                </tr>
                                <tr>
                                    <th><p>yt.apk</p></th>
                                    <th>
                                        <p>fc54078c5ae26d856</p>
                                        <p>109d306c37909ae</p>
                                    </th>
                                    <th>
                                        <p>8beab9e454b5283e892</p>
                                        <p>aeca6bca9afb608fa8718</p>
                                    </th>
                                    <th>
                                        <p>fbde6f65c960c2469d957f1fdb6d7240bd</p>
                                        <p>6eec5e4f34b68e01dda85cb9bf6841</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Piya Sharma.apk</p></th>
                                    <th>
                                        <p>c350c3cd119e51747</p>
                                        <p>7b35e6830947531</p>
                                    </th>
                                    <th>
                                        <p>14110facecceb016c694f</p>
                                        <p>04814b5e504dc6cde61</p>
                                    </th>
                                    <th>
                                        <p>9fdbe6f05d2ce4baa7819a0789caa3b49a</p>
                                        <p>835093193370ba49bdc4dfd4d9c7c7</p>
                                    </th>
                                </tr>
                            </Table>
                            <p>IP Addresses 95.111.247.73:18892</p>
                            <p>146.112.61.107:14862</p>
                            <p>209.127.19.241:10284</p>
                            <p>142.250.182.206:443</p>
                            <p>142.250.67.142:443</p>
                            <br />
                            <br />
                            <p>Domains</p>
                            <p>ptzbubble.shop</p>
                            <p>m.youtube.com</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Technical Analysis of a Malicious Android App'  , url: 'https://sequretek.upriselabs.io/resources/blog/technical-analysis-of-a-malicious-android-app'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
