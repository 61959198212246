// ############################ Require packages here ############################
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';

// ############################ Require files here ###############################

import { SampleNextArrow, SamplePrevArrow } from '../../utils/miscellneous';
import { getTestimonial } from './testimonialData';

export default function TestimonialSlider(props) {
    const [activeSlide, setActiveSlide] = useState(0);
    const [sliderData, setSliderData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const fetchTestimonialData = async () => {
            try {
                const data = await getTestimonial(props.page);
                setSliderData(data);
            } catch (error) {
                console.log('Error fetching data from testimonialData:', error);
            }
        };
        fetchTestimonialData();
    }, [props.page]);

    const renderTestimonial = (testimonial, index) => {
        if (testimonial.type === 'youtube') {
            return (
                <div className='display'>
                    <div className="review-slide" style={{ opacity: activeSlide === index ? 1 : 0.4 }}>
                        <div className="video-player" >
                            <ReactPlayer className="player-style" 
                                onTouchStart={(e)=>e.preventDefault()}
                                url={`${testimonial.link}?rel=0&modestbranding=1&iv_load_policy=3`} width="100%" controls={true}
                                key={index}
                                playing={activeSlide === index && isPlaying}
                                onPlay={() => setIsPlaying(true)}
                                onPause={() => setIsPlaying(false)}
                                onEnded={() => setIsPlaying(false)}
                                config={{
                                youtube: {
                                    playerVars: { showinfo: 1, rel: 0, },
                                    embedOptions: {
                                        host: 'https://www.youtube-nocookie.com'
                                    }
                                }
                            }} 
                            />
                        </div>
                        {/* <div className="video-player">
                            <iframe
                                src={`https://www.youtube.com/embed/${testimonial.link.split("v=")[1].split("&")[0]}`}
                                title={`YouTube Video ${index + 1}`}
                                frameBorder="0"
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" , borderRadius:'32px'}}
                                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div> */}
                    </div>
                </div>
            );
        } else if (testimonial.type === 'external') {
            return (
                <div className="review-slide" style={{ opacity: activeSlide === index ? 1 : 0.4 }}>
                    <div className='review-text' style={{ background: `linear-gradient(180deg, rgba(25, 10, 115, 0.85) 10.63%, rgba(32, 36, 73, 0.8) 78.13%, rgba(78, 7, 35, 0.9) 95%, #970102 111.88%)`, opacity: activeSlide === index ? 1 : 0.4 }} >
                        <h3 className="review-text-head">{testimonial?.title}</h3>
                        {window.innerWidth > 767 ? <p className="review-text-para">{testimonial?.description}</p> : null}
                        {/* {window.innerWidth > 767 ? null : <div><img src='/images/line-queue.svg' alt='' className='seq-line-queue-img' /></div>} */}
                        <div className="review-author">
                            {window.innerWidth > 767 ? <div className='review-border'></div> : null}
                            <div>
                                <p className="review-name">{testimonial.function}, {testimonial.industry}</p>
                            </div>
                            <div className='review-slide-log-link'>
                                {window.innerWidth > 767 ? 
                                <img src={testimonial.reviewer === 'gartner' ? '/images/Gartner-logo-full2.png' : '/images/Satya-nadella.svg'} alt='' className='logo' />
                                :
                                <img src={testimonial.reviewer === 'gartner' ? '/images/gartner-mobile.svg' : '/images/Satya-nadella.svg'} alt='' className='logo' />
                                }
                                <a className='review-slide-link d-flex align-items-center' rel='noreferrer' href={testimonial.link} target='_blank' >Read Full Review&nbsp;&nbsp;<img src='/images/arrow.png' alt='' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (testimonial.type === 'external2') {
            return (
                <div className="review-slide" style={{ opacity: activeSlide === index ? 1 : 0.4 }}>
                    <div className='review-text' style={{ background: `linear-gradient(180deg, rgba(25, 10, 115, 0.85) 10.63%, rgba(32, 36, 73, 0.8) 78.13%, rgba(78, 7, 35, 0.9) 95%, #970102 111.88%)`, opacity: activeSlide === index ? 1 : 0.4 }} >
                    {window.innerWidth > 767 ? 
                    <div>
                        <p className="review-text-para" style={{height:'auto'}}>{testimonial?.description}</p>
                        <div className="review-author">
                            <div className='review-slide-log-link justify-content-start'>
                                <img src={testimonial.reviewer === 'gartner' ? '/images/Gartner-logo-full2.png' : '/images/Satya-nadella.svg'} alt='' className='logo' style={{width:'48px' , height:'48px'}} />
                                <div>
                                    <h5>{testimonial.name}</h5>
                                    <p>{testimonial.job}</p>
                                </div>
                            </div>
                        </div>
                    </div> 
                    : 
                    <div>
                        <div className='review-slide-log-link justify-content-start'>
                            <img src={testimonial.reviewer === 'gartner' ? '/images/Gartner-logo-full2.png' : '/images/Satya-nadella.svg'} alt='' className='logo' style={{width:'48px' , height:'48px'}} />
                            <div>
                                <h5>{testimonial.name}</h5>
                                <p>{testimonial.job}</p>
                            </div>
                        </div>
                        <p className="review-text-para-external2">{testimonial.description}</p>
                        <p style={{ color: '#ED0101', cursor: 'pointer' }}>Read More...</p>
                    </div>
                    }
                    </div>
                </div>
            );
        }
        return null;
    };

    const innerWidth = window.matchMedia("(min-width: 768px) and (max-width: 1023px)").matches
    const desktop = window.innerWidth > 1320
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: desktop ? "25%" : innerWidth ? '15%' : '15%', // Adjust the padding to show part of the next/previous slides
        dots: true,
        beforeChange: (current, next) => {
            setActiveSlide(next);
            setIsPlaying(false);
        },
        // arrows: true,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        // variableWidth: true,

    };
    const Mobilesettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        // centerMode: true,
        // centerPadding: "5%", // Adjust the padding to show part of the next/previous slides
        dots: true,
        arrows: false,
        beforeChange: (current, next) => {
            setActiveSlide(next);
            setIsPlaying(false);
        },
    };

    // TESTIMONIAL SLIDER WAS BEEN USED IN Home, Platform, Product - NG-SIEM, Product - Identity, Product - EDR
// Product - Compliance Manager, Partners, Industries - SMB, Industries - Banking, Industries - Finance, Industries - Insurance, Industries - Manufacturing, Industries - Healthcare
// Industries - Pharma, Industries - Retail, Industries - Technology, Industries - Government all this pages

    return (
        <div className='seq-testimonials'>
            {/* section 1 TESTIMONIALS */}
            {window.innerWidth > 767 ?
                <div>
                    <Slider {...settings}>
                        {sliderData.map((testimonial, index) => renderTestimonial(testimonial, index))}
                    </Slider>
                </div>
                :
                <div className='container'>
                    <Slider swipe={true} {...Mobilesettings}>
                        {sliderData.map((testimonial, index) => renderTestimonial(testimonial, index))}
                    </Slider>
                </div>
            }
        </div>
    )
}
