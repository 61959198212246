// ############################ Require packages here ############################
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';


const risks = [
    { image: '/images/information.svg', title: 'Sensitive Patient Data', description: 'Healthcare organizations store vast amounts of sensitive patient data, making them attractive targets for cybercriminals.' },
    { image: '/images/disk.svg', title: 'Use of Outdated Technology', description: 'Many healthcare providers have cybersecurity limitations due to multiple constraints, mainly budgetary, leaving them open to attacks.' },
    { image: '/images/person.svg', title: 'Insider Threats', description: 'Dealing with insider threats, who work with sensitive information, is a major challenge in cybersecurity in healthcare.' },
    { image: '/images/risk.svg', title: 'Third-Party Providers', description: `Sharing confidential data with third parties for requisite operations introduces vulnerabilities that attackers can exploit` },
    { image: '/images/cloud.svg', title: 'Use of Connected Systems', description: 'Healthcare organizations use next-gen connected and access technologies that often lack robust protection, making them prone to attacks.' },
    { image: '/images/compliance.svg', title: 'Intellectual Property (IP) Protection', description: 'New formulae and unpatented drugs are invaluable healthcare IPs. Threat actors resort to IP theft in healthcare for quick profits.' },
    { image: '/images/shield-color.svg', title: 'Underestimation of Cyber Risk', description: `Some healthcare organizations assume erroneously that they are protected from cyberthreats, leaving them vulnerable to cyber crimes. ` },
    { image: '/images/user.svg', title: 'Shortage of Staff', description: `Healthcare organizations struggle to hire and retain cybersecurity staff amidst a talent shortage, increasing the risk of attacks. ` },
    { image: '/images/queue.svg', title: 'Unaware Workforce', description: `Companies with under-trained, unaware, or overly curious employees are vulnerable to exploitation by cyber adversaries.` },
    { image: '/images/shield-exclamation.svg', title: 'Emerging Cyber Risks', description: `Mergers, acquisitions, and geographical expansions increase touchpoints, and open new vulnerabilities, exposing organizations to cyberthreats.` },
    { image: '/images/fostering.svg', title: 'Compliance Regulations', description: `Mandatory compliance with all regulations like HIPAA, GLP, HITRUST, Compliance Monitoring Authority, and DST is complex and challenging. ` },
    { image: '/images/head.svg', title: 'Entities Founded and Run by Doctors', description: `Doctor-run companies invest in healthcare data security but may lack cybersecurity expertise to make informed decisions.` },
    { image: '/images/auction.svg', title: 'Class Action Suits', description: `Breached data leads to patients filing class action suits resulting in reputational damage and financial losses. ` },
    { image: '/images/heart-rate.svg', title: 'Patient Safety', description: `Disruption in healthcare services due to cyberattacks can affect patient safety and health.` }
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (CTEM) continuously monitors IT infrastructure for threats and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM, SOAR products and services help monitor, detect, and respond to cyberattacks in real-time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: `Our tailored governance and compliance reports help adhere to HIPAA, GLP, GDPR, SEC, HITRUST, DPDPA, CERT-In, and other regulations.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]



export default function HealthCare() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 5000);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad01d1e5c52d1577f915f']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Strengthen Healthcare Cybersecurity</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Shield your healthcare organization from cyberattacks. Avoid data breaches, maintain patient confidentiality, and adhere to regulations." />
                <meta name="keywords" content="Cybersecurity in Healthcare, Healthcare cybersecurity" />
                <meta property="og:title" content="Sequretek | Strengthen Healthcare Cybersecurity" />
                <meta property="og:description" content="Shield your healthcare organization from cyberattacks. Avoid data breaches, maintain patient confidentiality, and adhere to regulations." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/bg-img13.png')` }}>
                <div className='seq-description'>
                    <h5 data-aos="fade-up">Industries{' > '}HealthCare </h5>
                    <h2 data-aos="fade-up">Advanced Cybersecurity in Healthcare:
                        <p> Protecting the Medical Sector’s Digital Heartbeat</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row  me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Digital Defenses Against Cyberthreats:
                                <br />
                                Safeguarding Healthcare Data Integrity
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>At the core of the healthcare sector lies the promise of uninterrupted care at every touchpoint of a vast network of providers. However, a surge of complex cyberthreats is impeding the delivery of this promise. Data breaches causing identity thefts with devastating consequences and eroding patient trust, makes healthcare data security a prime concern. Sequeretek's comprehensive cybersecurity offerings help healthcare providers reinstate the trust factor, by guarding their digital environment and enabling uninterrupted care.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 METRICS */}
            <div className='seq-healthcare' data-aos="fade-up" style={{ backgroundImage: `linear-gradient(169.17deg, #040C30 32.48%, rgba(4, 12, 48, 0.9) 56.94%, #050D31 76.15%),url('/images/bg-img36.png')` }}>
                <div className='container px-0'>
                    <div className='healthcare-matrices '>
                        <h3>Critical Healthcare Cybersecurity Metrics</h3>
                        <div className={window.innerWidth > 767 ? 'row mt-5' : 'row mt-2'}>
                            <div className='col-md-3 col-12 '>
                                <div className={window.innerWidth > 767 ? 'matrices d-flex flex-column align-items-center' : 'matrices d-flex align-items-center'}>
                                    <img src='/images/chart-icon.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className=''>
                                        <h5>$21,500 - $47,500</h5>
                                        <p>Average Financial Losses</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 '>
                                <div className={window.innerWidth > 767 ? 'matrices d-flex flex-column align-items-center' : 'matrices d-flex align-items-center'}>
                                    <img src='/images/usd-circle.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className=''>
                                        <h5>$10.93 million</h5>
                                        <p>Cost due to breaches</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 '>
                                <div className={window.innerWidth > 767 ? 'matrices d-flex flex-column align-items-center' : 'matrices d-flex align-items-center'}>
                                    <img src='/images/up-arr.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className=''>
                                        <h5>44%</h5>
                                        <p>Increase in cyberattacks
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-12 '>
                                <div className={window.innerWidth > 767 ? 'matrices d-flex flex-column align-items-center' : 'matrices d-flex align-items-center'}>
                                    <img src='/images/users.svg' alt='' />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <div className=''>
                                        <h5>112 million</h5>
                                        <p>Individuals impacted in 2023 from 540+ organizations

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 CHALLENGES */}
            <div className='seq-challenge'>
                <h3>Navigating Healthcare's Cybersecurity Landscape:
                    <br />
                    <span style={{ color: '#B6BCBF' }}>Decoding Key Challenges</span>
                </h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 335 }}>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 5 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps '>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: `url('/images/bg-img58.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className='col-md-5 col-12 seq-help-left seq-help-sm'>
                                <p>How Sequretek Helps</p>
                                <h3>Enhancing Healthcare Cybersecurity: Bridging Compliance, Access, and Threat Defense.
                                </h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4  offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 6 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'healthcare'}
                />
            </div>
            {/* section 7 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img59.svg'}
                            mobilebg={'/images/bg-img60.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 8 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='healthcare'
                                    type=''
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
