// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import './resources.scss';
import Paginations from '../../../utils/pagination';
import { resetResourceLoader, getExternalResources, getExtIndustries, getExtTopics, getTypes, unlockResources } from '../../../Redux/ActionCreators/resourcesAction';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down-white.svg' width={20} alt='' />
        </components.DropdownIndicator>
    );
};


const types = [{ type: 'blog', label: 'Blog' }, { type: 'case-study', label: 'Case Studies' }, { type: 'datasheet', label: 'DataSheets' }, { type: 'demo', label: 'Demos' }, { type: 'event', label: 'Events & Webinars' },
{ type: 'infographic', label: 'Infographics' }, { type: 'podcast', label: 'Podcasts' }, { type: 'report', label: 'Reports' }, { type: 'advisory', label: 'Threat Advisories' }, { type: 'video', label: 'Videos' },
{ type: 'whitepaper', label: 'White Papers' }
]


export default function ResourcesCenter() {
    const [active, setActive] = useState('');
    const [toggle, setToggle] = useState(false);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [topic, setTopic] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState({ name: '', email: "", newsletter: true });
    const [resource, setResource] = useState(null);
    const [url, setUrl] = useState('');
    const { isLoadingResource, externalResources, total, extIndustries, extTopics, extTypes } = useSelector(state => state.resources);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const timerRef1 = React.useRef(null);
    const size = 12

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(resetResourceLoader());
        dispatch(getExternalResources(1, '', active, '', '', size));
        dispatch(getExtIndustries());
        dispatch(getExtTopics());
        dispatch(getTypes());
    }, [])

    useEffect(() => {
        const firstRender = async () => {
            if (location?.state?.type) {
                setActive(location?.state?.type);
                await dispatch(resetResourceLoader());
                await dispatch(getExternalResources(1, '', location?.state?.type, '', '', size));
            }
        }

        firstRender();
    }, [location.state?.type])

    const searchResource = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }

    const delaySearch = async (val) => {
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, val, '', '', '', size));
    }

    const selectTypeFilter = async (val) => {
        if (val === 'event') {
            navigate('/resources/upcoming-events-webinars');
        }
        else {
            setActive(val);
            setPage(1);
            setQuery('');
            await dispatch(resetResourceLoader());
            await dispatch(getExternalResources(1, '', val, '', '', size));
        }
    }

    const selectTopic = async (e) => {
        setTopic(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', '', e ? e?.value : '', '', size));
    }

    const selectIndustries = async (e) => {
        setIndustry(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', '', '', e ? e.value : '', size));
    }

    const selectTypes = async (e) => {
        setActive(e);
        setPage(1);
        setQuery('');
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(1, '', e ? e.value : "", '', '', size));
    }

    const handlePageChange = async (page) => {
        window.scrollTo(0, 0);
        await dispatch(resetResourceLoader());
        await dispatch(getExternalResources(page, '', active || active?.value, topic?.value, industry?.value, size));
    }

    const validationSchema = Yup.object({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9\s\-()&,]+$/, 'Name should have alphabets, numbers, space, hyphen, brackets, comma and ampersand only')
            .required('Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/, 'Enter Only Business email address'),
    });

    const handleSubmit = async (values) => {
        const { type, title, url } = resource
        let obj = {
            name: values?.name,
            email: values?.email,
            resource_id: resource?._id,
            type: type,
            newsletter: values?.newsletter
        }
        const res = await dispatch(unlockResources(obj));
        if (res?.type === 'UNLOCK_RESOURCES') {

            if (type === 'blog') {
                window.location.href = url
            } else if (type === 'video') {
                navigate(`/resources/video-library/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'advisory') {
                navigate(`/resources/threat-advisories/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'report') {
                navigate(`/resources/report/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'datasheet') {
                navigate(`/resources/datasheet/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'infographic') {
                navigate(`/resources/infographics/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'whitepaper') {
                navigate(`/resources/sequretek-white-paper/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'case-study') {
                navigate(`/resources/case-study/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'podcast') {
                navigate(`/resources/podcasts/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'demo') {
                navigate(`/resources/live-product-demos/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'event') {
                window.open(resource?.url, '_blank')
            }
            else if (type === 'factsheet') {
                navigate(`/resources/factsheet/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
        }
        setIsOpen(false)
    }

    const openLock = (e, el) => {
        e.preventDefault();
        e.stopPropagation();
        setResource(el)
        setUrl(el.url);
        setIsOpen(true);
    }

    const openContent = async (e, el) => {
        setResource(el)
        const { type, title, gated, url } = el
        if (gated) {
            await openLock(e, url)
        } else {
            if (type === 'blog') {
                window.location.href = el.url
            } else if (type === 'video') {
                navigate(`/resources/video-library/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'advisory') {
                navigate(`/resources/threat-advisories/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'report') {
                navigate(`/resources/report/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'datasheet') {
                navigate(`/resources/datasheet/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'infographic') {
                navigate(`/resources/infographics/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'whitepaper') {
                navigate(`/resources/sequretek-white-paper/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'case-study') {
                navigate(`/resources/case-study/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'podcast') {
                navigate(`/resources/podcasts/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'demo') {
                navigate(`/resources/live-product-demos/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'event') {
                window.open(el?.url, '_blank')
            }
            else if (type === 'factsheet') {
                navigate(`/resources/factsheet/${encodeURIComponent(title)}`, { state: { data: el } })
            }
        }
    }

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#0e49b5'
        }),
        option: (provided, state) => ({
            ...provided,
            textTransform: "capitalize",
            backgroundColor: state.isSelected ? 'lightblue' : '#0e49b5', // Change background color on selection
            color: 'white', // Ensure text is visible
            ':hover': {
                backgroundColor: 'lightgrey' // Change background color on hover
            }
        })
    };
    return (
        <div className='seq-resource-center'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Resources Hub</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore Sequretek’s resource hub for essential cybersecurity knowledge and best practices. Strengthen your security posture with expert insights and guidance." />
                <meta property="og:title" content="Sequretek | Resources Hub" />
                <meta property="og:description" content="Explore Sequretek’s resource hub for essential cybersecurity knowledge and best practices. Strengthen your security posture with expert insights and guidance." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-resource-header'>
                <div style={{ width: window.innerWidth > 1024 ? '60%' : '77%' }}>
                    <h2>Resource Center</h2>
                    <p>Our comprehensive Resources section offers a wealth of information on the latest advancements and best practices in cybersecurity.  We invite you to explore, learn, and ensure your security in this increasingly interconnected world.</p>
                </div>
            </div>
            {/* section 2 RESOURCE LAYOUT */}
            <div className='seq-resource-layout'>
                <div className='container'>
                    {active === '' ? null :
                        <div className='breadcrumb'>
                            <ul>
                                <li><span onClick={() => setActive('')}>Resources</span>
                                    <span>
                                        {
                                            types?.map(el => {
                                                return (<Fragment>
                                                    {active === el.type ? ` >  ${el.label}` : null}
                                                </Fragment>)
                                            })
                                        }</span></li>
                            </ul>
                        </div>
                    }
                    {window.innerWidth > 1023 ?
                        <div className='resource-filter mt-2'>
                            <div className="has-search"  >
                                <span className="form-control-feedback" >
                                    <img src="/images/search.svg" alt='' width={12} />
                                </span>
                                <input type="search" className="form-control" placeholder='Search for resource' value={query} onChange={(e) => searchResource(e.target.value)} />
                            </div>
                            {/* <div> */}

                            <div style={{ width: '20%' }}>
                                <Select
                                    components={{ DropdownIndicator }}
                                    options={extTopics}
                                    onChange={selectTopic}
                                    placeholder='Select Topic'
                                    value={topic}
                                    styles={customStyles}
                                    isClearable
                                />
                            </div>
                            <div style={{ width: '20%' }}>
                                <Select
                                    components={{ DropdownIndicator }}
                                    options={extIndustries}
                                    onChange={selectIndustries}
                                    placeholder='Select Industry'
                                    value={industry}
                                    styles={customStyles}
                                    isClearable
                                />
                            </div>
                            {/* </div> */}
                        </div> :
                        <div className='resource-filter'>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <div className="has-search"  >
                                    <span className="form-control-feedback" >
                                        <img src="/images/search.svg" alt='' width={12} />
                                    </span>
                                    <input type="search" className="form-control" placeholder='Search for resource' />
                                </div>&nbsp;&nbsp;
                                <button className='filter-btn' style={{ backgroundColor: toggle ? '#0e49b5' : '' }}><img src='/images/filter.svg' alt='' onClick={() => setToggle(!toggle)} /></button>
                            </div>
                            {toggle ?
                                <Fragment>
                                    <div className='' style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={extTopics}
                                            onChange={selectTopic}
                                            placeholder='Select Topic'
                                            value={topic}
                                            styles={customStyles}
                                            isClearable
                                        />

                                    </div>
                                    <div className='mt-2' style={{ width: '100%' }}>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={extIndustries}
                                            onChange={selectIndustries}
                                            placeholder='Select Industry'
                                            value={industry}
                                            styles={customStyles}
                                            isClearable
                                        />
                                    </div>
                                </Fragment>
                                : null
                            }
                            <div className='type-filter mt-2 w-100'>
                                <Select
                                    components={{ DropdownIndicator }}
                                    options={extTypes}
                                    isClearable
                                    onChange={selectTypes}
                                    placeholder='Select Type'
                                    value={active}
                                    styles={customStyles}
                                />
                            </div>

                        </div>
                    }
                    <div className='resources-list'>
                        <div className='row'>
                            <div className='col-md-9 col-sm-12'>
                                <div className='row mt-3'>
                                    {isLoadingResource ?
                                        <div className='text-center'>
                                            <img src='/images/loader.svg' alt='' height={400} />
                                        </div>
                                        :
                                        externalResources?.length > 0 ?
                                            externalResources?.map(el => {
                                                return (
                                                    <div className={window.innerWidth > 767 ? 'col-md-4  d-flex align-self-stretch cursor-pointer mt-3' : 'col-md-12 d-flex justify-content-center cursor-pointer mt-3'} loading="lazy">
                                                        <div className="card w-100" onClick={(e) => el.gated ? openLock(e, el) : openContent(e, el)}>
                                                            <img className="card-img-top position-relative" src={el.image ? el.image : '/images/sample2.png'} alt='' loading="lazy"
                                                                onError={(e) => {
                                                                    if (e.target.src !== '/images/sample2.png') {
                                                                        e.target.onerror = '/images/sample2.png';
                                                                        e.target.src = '/images/sample2.png'
                                                                    }
                                                                }} />
                                                            <div className='type'>
                                                                <div className='type-1' >
                                                                    {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                        el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                            el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> :
                                                                                el.type === 'factsheet' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                                                    }&nbsp;&nbsp;
                                                                    {el.type}
                                                                </div>
                                                                {el.gated ?
                                                                    <div className='type-1 ms-2' onClick={(e) => openLock(e, el)} style={{ padding: '4px 10px', background: '#A2DAE0' }}>
                                                                        <img src='/images/lock-icon.svg' alt='' />
                                                                    </div> :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className="card-body">
                                                                <h5 className="card-title elipsis">{el.title}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) :
                                            <p className='text-center text-white mt-5'>
                                                No Resources Available
                                            </p>
                                    }

                                </div>
                                {total > 1 ?
                                    <div className='d-flex justify-content-start mt-3'>
                                        <Paginations className={'custom-pagination1'} totalPages={total} onPageChange={handlePageChange} />
                                    </div> :
                                    null
                                }

                            </div>
                            <div className='col-md-3'>
                                <div className='ul right-filter '>
                                    <li onClick={() => selectTypeFilter('')} className={active === '' ? 'active' : ''}>All Resources</li>
                                    {
                                        extTypes?.map(el => {
                                            return (
                                                <li onClick={() => selectTypeFilter(el.value)} className={el.value === active ? 'active text-capitalize' : 'text-capitalize'} >
                                                    {
                                                        el.value === active ?
                                                            (el.value === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.value === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.value === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                                                el.value === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.value === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.value === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                                    el.value === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.value === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.value === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.value === 'factsheet' ? <img src='/images/webinar-icon.svg' alt='' /> : null) :
                                                            (el.value === 'report' ? <img src='/images/report-icon-color.svg' alt='' /> : el.value === 'advisory' ? <img src='/images/advisory-icon-color.svg' alt='' /> : el.value === 'podcast' ? <img src='/images/podcast-icon-color.svg' alt='' /> :
                                                                el.value === 'demo' ? <img src='/images/demo-icon-color.svg' alt='' /> : el.value === 'datasheet' ? <img src='/images/datasheet-icon-color.svg' alt='' /> : el.value === 'case-study' ? <img src='/images/case-study-icon-color.svg' alt='' /> :
                                                                    el.value === 'blog' ? <img src='/images/blog-icon-color.svg' alt='' /> : el.value === 'infographic' ? <img src='/images/infographics-color.svg' alt='' /> : el.value === 'whitepaper' ? <img src='/images/whitepaper-icon-color.svg' alt='' /> : el.value === 'event' ? <img src='/images/event-icon-color.svg' alt='' /> : el.value === 'video' ? <img src='/images/video-icon-color.svg' alt='' /> : el.value === 'factsheet' ? <img src='/images/webinar-icon-color.svg' alt='' /> : null)
                                                    } &nbsp;&nbsp;
                                                    {el.displayname}
                                                </li>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ##############Add modal ########################### */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-label-vcenter"
                centered
                dialogClassName={"unlock-modal"}
            >
                <Modal.Body style={{ position: 'relative', padding: '35px 40px' }}>
                    <img src='/images/unlock.svg' alt='' />
                    <h4>Unlock Resource</h4>
                    <p>To access this resource enter your business email </p>
                    <div
                        className="sticky-close"
                        onClick={() => setIsOpen(false)}
                    >
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                    {/* section 2 USER FORM */}
                    <div className=''>
                        <Formik initialValues={details} validationSchema={validationSchema} onSubmit={handleSubmit}>
                            {() => (
                                <Form className="user_form">
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-12 mt-2">
                                            <div className="form-group">
                                                <Field type="text" name="name" className="form-control" placeholder="*Name" />
                                                <ErrorMessage name="name" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="form-group">
                                                <Field type="text" name="email" className="form-control" placeholder="*Business Email" />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 mt-2">
                                            <div className='form-group'>
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkbox"
                                                        name='newsletter'
                                                    // value={false}

                                                    />
                                                    <label className="form-check-label" htmlFor="checkbox">
                                                        Receive the latest threat advisory and newsletters from Sequretek
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className='w-100 button-color h-48' >Submit</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </div >
    )
}

