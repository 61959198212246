// ############################ Require packages here ############################
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';


const risks = [
    { image: '/images/fostering.svg', title: 'Compliance', description: 'The challenge extends beyond compliance reporting, requiring staying current with evolving mandates to protect client data and business continuity.' },
    { image: '/images/padlock-check.svg', title: 'Security Risk', description: 'Financial institutions are prime targets for cybercriminals seeking profits, access to information, or manipulation of financial records.' },
    { image: '/images/information.svg', title: 'Data Protection', description: 'Financial institutions with personal data like social security and credit details are targets for data breaches, identity theft, and scams.' },
    { image: '/images/shield-color.svg', title: 'Technological Vulnerabilities', description: `Financial institutions depend on digital infrastructure and face cyberattacks due to old security patches and no ongoing support.` },
    { image: '/images/compliance.svg', title: 'Governance', description: `Effective cybersecurity policies are crucial for financial institutions to manage risks, protect data, and ensure resilience against threats.` },
    { image: '/images/chart-icon.svg', title: 'Limited Resources', description: 'A shortage of skilled professionals and small cybersecurity budgets make many small financial institutions struggle with security.' },
    { image: '/images/information.svg', title: 'Reputational Damage', description: `Cyberattacks damage reputation while non-compliance with regulations erodes customer trust, worsening the financial impact.` },
    { image: '/images/risk.svg', title: 'Third-Party Risk', description: 'Outsourcing to third-party vendors introduces cybersecurity risks, especially if a vendor’s security system is breached.' },
    { image: '/images/person.svg', title: 'Insider Threats', description: `These are a major concern as employees and stakeholders with access to sensitive data can cause breaches through errors or malicious actions.` }
]

const helpList = [{
    image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Our products adopt the Percept Continuous Threat Exposure Management (CTEM) framework to monitor your attack surface to identify malware and potential breaches.`
},
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: `Sequretek's user access governance solutions control access to sensitive data with regular reviews and activity monitoring.` },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: `Sequretek's solutions simplify compliance with standards like PCI DSS, GDPR, and others. Security risks and compliance reports are well managed.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: `Sequretek's audits identify unpatched systems and update security software, effectively eliminating security gaps and maintaining uptime.` },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]



export default function FinancialServices() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4200);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }


    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66daceeb1e5c52d1577f9151']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Secure Financial Data & Ensure Compliance | Cybersecurity</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Discover how financial services firms can tackle third party risk and insider threats with robust cybersecurity measures tailored for the fintech industry." />
                <meta name="keywords" content="Fintech, financial services cyber security, Cyber threat" />
                <meta property="og:title" content="Sequretek | Secure Financial Data & Ensure Compliance | Cybersecurity" />
                <meta property="og:description" content="Discover how financial services firms can tackle third party risk and insider threats with robust cybersecurity measures tailored for the fintech industry." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/bg-img11.png')` }}>
                <div className='seq-description seq-description-small'>
                    <h5 data-aos="fade-up">Industries{' > '}Financial Services </h5>
                    <h2 data-aos="fade-up">Protect Your Financial Institution From Cyberthreats and Secure Your Future
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row  me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Invest in Financial Services Cybersecurity to Protect Data and Ensure Compliance
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>
                                Safeguarding stakeholder trust and sensitive data is paramount in the financial services industry, a prime target for cybercriminals. In late 2023 alone, over one million cyberattacks were reported, highlighting the inadequacy of traditional defenses. AI is crucial for effective threat detection and mitigation, yet many firms neglect basic data security practices. Continuous investment in cybersecurity is essential for transaction integrity, data protection, and regulatory compliance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Risk Radar</p>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 4 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: window.innerWidth > 767 ? `url('/images/bg-img52.svg')` : `url('/images/bg-img6.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>Strengthen Your Cybersecurity With Sequretek: AI Solutions for the Finance Sector</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4  offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4  col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 TESTIMONIAL SLIDER*/}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'finance'}
                />
            </div>
            {/* section 6 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img53.svg'}
                            mobilebg={'/images/bg-img54.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 7 RELATED RESOURCES*/}
            <div className='seq-resources'>
                <div className='container ps-0'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='financial-services'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
