// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Aos from 'aos';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import 'aos/dist/aos.css'
import "slick-carousel/slick/slick-theme.css";
import RelatedResources from './relatedResources';
import TestimonialSlider from './testimonialSlider';



const IndustriesCard = [
    { id: 1, img: '/images/bank.svg', text: 'Banking' },
    { id: 2, img: '/images/badge-dollar.svg', text: 'Finance' },
    { id: 3, img: '/images/umbrella.svg', text: 'Insurance' },
    { id: 4, img: '/images/industry-windows.svg', text: 'Manufacturing' },
    { id: 5, img: '/images/hand-holding-medical.svg', text: 'Healthcare' },
    { id: 6, img: '/images/pills.svg', text: 'Pharmaceuticals' },
    { id: 7, img: '/images/shopping-cart-add.svg', text: 'Retail' },
    { id: 8, img: '/images/sensor-on.svg', text: 'Technology' },
    { id: 9, img: '/images/people-roof.svg', text: 'Government' },
    { id: 10, img: '/images/store-alt.svg', text: 'Small & Medium Businesses' },
]


export default function Home() {
    const [tabActive, setTabActive] = useState('products');
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoveredItemTwo, setHoveredItemTwo] = useState(null);
    const [hoveredCard, setHoveredCard] = useState(null);
    const [isHovered, setIsHovered] = useState(null);
    // const [scrolly, setScrolly] = useState();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        Aos.init({ duration: 1000 })
    }, [])

    const industryCardset1 = [
        { name: 'Banking', img: '/images/bank.svg', hoverImg: '/images/bank-wight.svg' },
        { name: 'Finance', img: '/images/badge-dollar.svg', hoverImg: '/images/badge-dollar-wight.svg' },
        { name: 'Insurance', img: '/images/umbrella.svg', hoverImg: '/images/umbrella-wight.svg' },
        { name: 'Manufacturing', img: '/images/industry-windows.svg', hoverImg: '/images/industry-windows-wight.svg' },
        { name: 'Healthcare', img: '/images/hand-holding-medical.svg', hoverImg: '/images/hand-holding-medical-wight.svg' },
    ];
    const industryCardset2 = [
        { name: 'Pharmaceuticals', img: '/images/pills.svg', hoverImg: '/images/pills-wight.svg' },
        { name: 'Retail', img: '/images/shopping-cart-add.svg', hoverImg: '/images/shopping-cart-add-wight.svg' },
        { name: 'Technology', img: '/images/sensor-on.svg', hoverImg: '/images/sensor-on-wight.svg' },
        { name: 'Government', img: '/images/people-roof.svg', hoverImg: '/images/people-roof-wight.svg' },
        { name: 'Small & Medium Businesses', img: '/images/store-alt.svg', hoverImg: '/images/store-alt-wight.svg' },
    ];

    const productsCards = [{
        title: 'Percept XDR & NG SIEM',
        description: 'AI-powered Percept XDR & NG SIEM offers 24/7 security monitoring, threat detection, and incident response for your entire digital landscape',
        page: 'perceptXdr'
    }, {
        title: 'Percept Identity',
        description: 'Zero-trust-centric automated user life cycle management, with role-based access control and recertification across hybrid environments',
        page: 'PerceptIdentity'
    }, {
        title: 'Percept EDR',
        description: 'On-agent AI-based detection and response for endpoints, servers, clouds with application whitelisting, vulnerability and patch management',
        page: 'perceptEdr'
    }, {
        title: 'Percept Compliance Manager',
        description: 'Continuous governance, risk, compliance across environments with vulnerability assessment and configuration audits on a single console',
        page: 'perceptManager'
    }]

    const serviceCards = [
        {
            title: 'Governance, Risk and Compliance',
            description: 'Support adherence to governance requirements, the latest standards, and industry-specific compliance mandates, while mitigating risks',
            page: 'governancerisk'
        },
        {
            title: 'Security Operations and Management',
            description: 'Deployment, configuration, and security operations and management of networks, endpoints, email, data centers, and cloud environments',
            page: 'securitymanage'
        },
        {
            title: 'Incident Response and Management',
            description: 'Prompt incident response, effective management of cyber risk, and restoration of operations through mitigation, with minimal downtime',
            page: 'incidentmanage'
        },
        {
            title: 'Patch Management as a Service',
            description: 'Patch inventory, identification of vulnerability, testing, and automated deployment of patches, compliance reporting, and risk mitigation',
            page: 'pathmanage'
        }
    ]

    const navigate = useNavigate();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'demo') {
            navigate('/schedule-a-demo')
        } else if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'securitymanage') {
            navigate('/services', { state: { active: 'securitymanage' } })
        } else if (val === 'governancerisk') {
            navigate('/services', { state: { active: 'governancerisk' } })
        } else if (val === 'incidentmanage') {
            navigate('/services', { state: { active: 'incidentmanage' } })
        } else if (val === 'pathmanage') {
            navigate('/services', { state: { active: 'pathmanage' } })
        } else if (val === 'Banking') {
            navigate('/industries/cybersecurity-for-banks')
        } else if (val === 'Finance') {
            navigate('/industries/financial-institution-cybersecurity')
        } else if (val === 'Insurance') {
            navigate('/industries/insurance-cybersecurity')
        } else if (val === 'Manufacturing') {
            navigate('/industries/manufacturing-cybersecurity')
        } else if (val === 'Healthcare') {
            navigate('/industries/healthcare-cybersecurity')
        } else if (val === 'Pharmaceuticals') {
            navigate('/industries/pharma-data-protection')
        } else if (val === 'Retail') {
            navigate('/industries/retail-cybersecurity')
        } else if (val === 'Government') {
            navigate('/industries/cybersecurity-for-government')
        } else if (val === 'Small & Medium Businesses') {
            navigate('/industries/smb-cybersecurity-solutions')
        } else if (val === 'Why Sequretek') {
            navigate('/sequretek-benefits')
        } else if (val === 'resources') {
            navigate('/resources-hub')
        } else if (val === 'Technology') {
            navigate('/industries/technology-cybersecurity')
        }
    }
    const handleMouseEnter = (index) => {
        setHoveredCard(index);
    };

    const handleMouseLeave = () => {
        setHoveredCard(null);
    };

    const handleServiceMouseEnter = (index) => {
        setIsHovered(index);
    }

    const handleServiceMouseLeave = () => {
        setIsHovered(null)
    }


    return (
        <div className='seq-home-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek: AI-Powered Cybersecurity Solutions for Enhanced Protection</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Secure your enterprise with our cutting-edge cybersecurity solutions. Protect your business from cyber threats with our AI-powered platform, products, and services." />
                <meta name="keywords" content="Cyber Security Solutions, Cybersecurity Company, AI Cyber security,Cloud Security Solution" />
                <meta property="og:title" content="Sequretek: AI-Powered Cybersecurity Solutions for Enhanced Protection" />
                <meta property="og:description" content="Secure your enterprise with our cutting-edge cybersecurity solutions. Protect your business from cyber threats with our AI-powered platform, products, and services." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-home-heading' style={{ backgroundImage: `linear-gradient(180deg, rgba(6, 6, 93, 0.5) 0%, #040C30 100%),url('/images/sqtk-banner.gif')` }}>
                <div className='container pe-0'>
                    <div className='seq-description'  >
                        <div className='seq-description-top-cards'>
                            {/* {window.innerWidth > 767 ?
                                <div data-aos="fade-up" className='seq-header-card d-flex align-items-center justify-content-center' >
                                    {HeadCard?.map(el => {
                                        return (
                                            <div className='seq-header-card-item'>
                                                <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                <p>{el.text}</p>
                                            </div>
                                        )
                                    })}
                                </div> :
                                <div data-aos="fade-up" className='seq-header-card'>
                                    {HeadCard?.map(el => {
                                        return (
                                            <div className='seq-header-card-item d-flex'>
                                                <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                <p className='d-flex align-items-center justify-content-center'>{el.text}</p>
                                            </div>
                                        )
                                    })}
                                </div>

                            } */}
                        </div>
                        <div className='seq-header-text' data-aos="fade-up" data-aos-delay='750'>
                            <h2>Reliable. Simple. Adaptive.</h2>
                            <h6>When it comes to cybersecurity, these are the words that matter the most. Our AI technologies, processes, and expert teams ensure your enterprise's IT assets are protected against cyberthreats. We’re there for you, every step of the way.</h6>
                            <div className='text-center mt-4'>
                                <button onClick={() => setPage('demo')}>Schedule a demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Session body */}
            <div className='seq-home-body' >
                {/* Session 1-1 IMAGE & ACCORDION */}
                <div className='seq-home-body1' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <div className='container pe-0 ps-0' data-aos="fade-up">
                        <div className='seq-home-body1-part1' >
                            <div>
                                <div className='d-flex align-items-center justify-content-center'><h2>AI-Powered Continuous Threat Exposure Management</h2></div>
                                <div className='d-flex align-items-center justify-content-center'><p>We offer end-to-end security in enterprise threat monitoring, incident response, device security, and identity and access governance through the adaptive AI-powered Percept platform and products, which use the Continuous Threat Exposure Management (CTEM) framework.</p></div>
                                <div className='d-flex align-items-center justify-content-center'><button onClick={() => setPage('perceptplatform')}>Explore the platform</button></div>
                            </div>
                        </div>
                        <div className='seq-home-body1-part2' data-aos="fade-up" data-aos-delay='150' >
                            {/* React animation */}
                            {window.innerWidth > 767 ?
                                <img src='/images/Home Diag.svg' alt='' />
                                :
                                <div className='seq-img-mobile'>
                                    <div className='d-flex justify-content-evenly seq-img-mobile-head'>
                                        <h4 onClick={() => setTabActive('products')} className={tabActive === 'products' ? 'active' : ''}>PRODUCTS</h4>
                                        <h4 onClick={() => setTabActive('services')} className={tabActive === 'services' ? 'active' : ''}>SERVICES</h4>
                                    </div>
                                    {tabActive === 'products' ?
                                        <div>
                                            <div className='seq-img-mobile-head-cards d-flex'>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>PERCEPT <br /> XDR & NG SIEM</h6>
                                                </div>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>PERCEPT IDENTITY</h6>
                                                </div>
                                            </div>
                                            <div className='seq-img-mobile-head-cards d-flex'>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>PERCEPT EDR</h6>
                                                </div>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>PERCEPT COMPLIANCE MANAGER</h6>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {tabActive === 'services' ?
                                        <div>
                                            <div className='seq-img-mobile-head-cards d-flex'>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>Governance, Risk, and Compliance</h6>
                                                </div>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>Incident Response and Management</h6>
                                                </div>
                                            </div>
                                            <div className='seq-img-mobile-head-cards d-flex'>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>Patch Management as a Service</h6>
                                                </div>
                                                <div className='seq-img-mobile-head-cards-items'>
                                                    <h6>Security Operations and Management</h6>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                    <div className='seq-img-powered'>
                                        <div className='d-flex justify-content-center' style={{ margin: '10px 0' }}>
                                            <div className='d-flex'><img src='/images/artificial-intelligence-blue.svg' alt='' />&nbsp;<p>AI-Powered</p></div>&nbsp;&nbsp;
                                            <div className='d-flex'><img src='/images/cubes-blue.svg' alt='' />&nbsp;<p>Modular</p></div>
                                        </div>
                                        <div className='d-flex justify-content-center'><img src='/images/puzzle-pieces-blue.svg' alt='' />&nbsp;<p>Complementary</p></div>
                                    </div>
                                    <div className='seq-img-threat'>
                                        <div className='seq-img-threat-head d-flex justify-content-center'><h5>Percept Continuous Threat Exposure Management Data Lake</h5></div>
                                        <div className='seq-img-threat-body'>
                                            <div className='d-flex' style={{ gap: '13px', margin: '13px 0' }}>
                                                <div className='seq-img-threat-body-items d-flex align-items-center justify-content-center'><img src='images/contact-lens-eyes-color.svg' alt='' />&nbsp;&nbsp;<p>Threat Intelligence</p></div>
                                                <div className='seq-img-threat-body-items d-flex align-items-center justify-content-center'><img src='images/chart-histogram-color.svg' alt='' />&nbsp;&nbsp;<p>Analytics</p></div>
                                            </div>
                                            <div className='d-flex' style={{ gap: '13px', margin: '13px 0' }}>
                                                <div className='seq-img-threat-body-items d-flex align-items-center justify-content-start'><img src='images/shield-check -color.svg' alt='' />&nbsp;&nbsp;<p>Security</p></div>
                                                <div className='seq-img-threat-body-items d-flex align-items-center justify-content-center'><img src='images/diamond-exclamation-color.svg' alt='' />&nbsp;&nbsp;<p>Response</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='seq-img-exist'>
                                        <div className='d-flex'><img src='/images/uparrows-color.svg' alt='' /><img src='/images/uparrows-color.svg' alt='' /><img src='/images/uparrows-color.svg' alt='' /></div>
                                        <h4>Coexist with any solution</h4>
                                        <h4>Ingest data from any source</h4>
                                    </div>
                                    <div className='d-flex'><img src='/images/uparrows-color-blue.svg' alt='' /><img src='/images/uparrows-color-blue.svg' alt='' /><img src='/images/uparrows-color-blue.svg' alt='' /><img src='/images/uparrows-color-blue.svg' alt='' /></div>
                                    <div className='seq-img-types d-flex justify-content-evenly'>
                                        <div className='seq-img-types-items'><div className='d-flex justify-content-center'><img src='/images/cloud-upload-alt.svg' alt='' /></div><p>Cloud</p></div>
                                        <div className='seq-img-types-items'><div className='d-flex justify-content-center'><img src='/images/network-analytic.svg' alt='' /></div><p>Network</p></div>
                                        <div className='seq-img-types-items'><div className='d-flex justify-content-center'><img src='/images/shield-keyhole.svg' alt='' /></div><p>Third-party Tools</p></div>
                                        <div className='seq-img-types-items'><div className='d-flex justify-content-center'><img src='/images/user-key-blue.svg' alt='' /></div><p>Identity</p></div>
                                        <div className='seq-img-types-items'><div className='d-flex justify-content-center'><img src='/images/grid-dividers.svg' alt='' /></div><p>Legacy Log Sources / Devices</p></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/* Session 2 PRODUCTS CARDS */}
                <div className='seq-home-body2' data-aos="fade-up" data-aos-delay='0' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-home-body2-sections'>
                            <div className='seq-home-body2-leftsection' >
                                <div className='card4'>
                                    <div><h2>Product Suite</h2></div>
                                    <div><p>Gain complete security visibility with our industry-certified suite of products. Whether extended detection and response, identity and access management, compliance management, or endpoint detection and response for your IT environment, rely on Sequretek for your cybersecurity.</p></div>
                                    <div><button onClick={() => setPage('perceptplatform')}>Explore the platform</button></div>
                                </div>
                            </div>
                            <div className='seq-home-body2-rightsection' >
                                <div className='seq-home-body2-rightsection-card'>
                                    {productsCards.map((card, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={`d-flex seq-home-body2-rightsection-card-items card${index + 1}`}
                                                // style={{transform : getTransform(index)}}
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                                onClick={() => setPage(card.page)}
                                            >
                                                <div className='card-text'>
                                                    <h3>{card.title}</h3>
                                                    <div className='d-flex flex-wrap'>
                                                        <p>{card.description}</p>
                                                        {window.innerWidth > 767 ? null : (
                                                            <div className='d-flex align-items-center justify-content-center'>
                                                                <img className='mt' src='/images/Round-right-arrow.svg' alt='' />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {window.innerWidth > 767 ? (
                                                    <div
                                                        className='d-flex align-items-center justify-content-center'
                                                        onClick={() => setPage(card.page)}
                                                    >
                                                        <img
                                                            src={hoveredCard === index ? '/images/Round-right-arrow-color.svg' : '/images/Round-right-arrow.svg'}
                                                            alt=''
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Session 3 SERVICES CARDS */}
                <div className='seq-home-body2 seq-home-body3' data-aos="fade-up" data-aos-delay='0' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <div className='container pe-0 ps-0'>
                        <div className='seq-home-body2-sections'>
                            <div className='seq-home-body2-leftsection' >
                                <div className='card4'>
                                    <div><h2>Services</h2></div>
                                    <div><p>Sequretek offers a wide range of Managed Security Services designed to protect every aspect of your IT environment.</p></div>
                                    <div><button onClick={() => setPage('securitymanage')}>Explore services</button></div>
                                </div>
                            </div>
                            <div className='seq-home-body2-rightsection'>
                                <div className='seq-home-body2-rightsection-card'>
                                    {serviceCards.map((card, index) => {
                                        return (
                                            <div className={`d-flex seq-home-body2-rightsection-card-items card${index + 1}`}
                                                key={index}
                                                onMouseEnter={() => handleServiceMouseEnter(index)}
                                                onMouseLeave={handleServiceMouseLeave}
                                                onClick={() => setPage(card.page)}
                                            >
                                                <div className='card-text'>
                                                    <h3>{card.title}</h3>
                                                    <div className='d-flex flex-wrap'>
                                                        <p>{card.description}</p>
                                                        {window.innerWidth > 767 ? null : <div className='d-flex align-items-center justify-content-center'><img className='mt' src='/images/Round-right-arrow.svg' alt='' /></div>}
                                                    </div>
                                                </div>
                                                {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center'><img src={isHovered === index ? '/images/Round-right-arrow-color.svg' : '/images/Round-right-arrow.svg'} alt='' /></div> : null}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Session 4 INDUSTRIES CARDS */}
                <div className='seq-home-body4' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <div className='container pe-0 ps-0'>
                        <div data-aos="fade-up" data-aos-delay='0'>
                            <div className='d-flex align-items-center justify-content-center'><h2>Industries</h2></div>
                            <div className='d-flex align-items-center justify-content-center'><h6 className='pt-2'>From banking to manufacturing, healthcare to retail, Sequretek’s proactive cybersecurity solutions protect enterprises of all sizes. Whether you are in government, or looking for reliable security for your small and medium enterprise, we have a solution for you.</h6></div>
                        </div>
                        <div>
                            {window.innerWidth > 767 ?
                                <div >
                                    <div className='seq-industries-card' >
                                        {industryCardset1.map((item, index) => (
                                            <div
                                                key={index}
                                                className='seq-Industries-card-items'
                                                data-aos="fade-up"
                                                data-aos-delay={50 + index * 100}
                                                onMouseEnter={() => setHoveredItem(index)}
                                                onMouseLeave={() => setHoveredItem(null)}
                                                onClick={() => setPage(item.name)}
                                            >
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <img src={hoveredItem === index ? item.hoverImg : item.img} alt='' />
                                                </div>
                                                <p className='d-flex align-items-center justify-content-center'>{item.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                    <div className='seq-industries-card' >
                                        {industryCardset2.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className='seq-Industries-card-items'
                                                    data-aos="fade-up"
                                                    data-aos-delay={50 + index * 100}
                                                    onMouseEnter={() => setHoveredItemTwo(index)}
                                                    onMouseLeave={() => setHoveredItemTwo(null)}
                                                    onClick={() => setPage(item.name)}
                                                >
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <img src={hoveredItemTwo === index ? item.hoverImg : item.img} alt='' />
                                                    </div>
                                                    <p className='d-flex align-items-center justify-content-center'>{item.name}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> :
                                <div className='d-flex overflow-auto flex-nowrap'>
                                    <div className='seq-industries-card'>
                                        {IndustriesCard?.map(el => {
                                            return (
                                                <div className='seq-Industries-card-items' onClick={() => setPage(el.text)} style={{ marginRight: '20px' }}>
                                                    <div className='d-flex align-items-center justify-content-center'><img src={el.img} alt='' /></div>
                                                    <p>{el.text}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {/* Session 5 SECURITY */}
                <div className='seq-home-body5'>
                    <div className='container pe-0 ps-0'>
                        <div>
                            <h3 data-aos="fade-up" data-aos-delay='0'>Enterprises Worldwide Trust Sequretek</h3>
                            <p data-aos="fade-up" data-aos-delay='0'>We’re a cybersecurity company<br /> that provides AI-powered enterprise <br />threat management to thwart cyberattacks.</p>
                            <button data-aos="fade-up" onClick={() => setPage('Why Sequretek')}>Why Sequretek</button>
                        </div>
                    </div>
                </div>
                {/* Session 6 TESTIMONIAL SLIDER */}
                <div className='seq-home-body6' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <TestimonialSlider
                        page={'home'}
                    />
                </div>
                {/* Session 7 DISCOVER SEQURETEK & RESOURCES*/}
                <div className='seq-home-body7' style={{ backgroundImage: `url('/images/bg-img44.svg')` }}>
                    <div className='container pe-0 ps-0'>
                        <div data-aos="fade-up" data-aos-delay='0'>
                            <h3>Discover Sequretek</h3>
                            <div className='d-flex align-items-center justify-content-center'><p>In an ever-evolving threat landscape, depend on a robust platform backed by world-class processes, AI technologies and hypervigilant teams to protect your business.</p></div>
                        </div>
                        <div className='seq-resources' data-aos="fade-up" data-aos-delay='50'>
                            <div className='container px-0'>
                                <div className='row ms-0 me-0'>
                                    <div className='col-md-12 ps-0'>
                                        <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto overflowY-hidden'}>
                                            <RelatedResources
                                                page='home'
                                                type=''
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center'><button onClick={() => setPage('resources')}>Visit the resource center</button></div> : <button onClick={() => setPage('resources')}>Resource Center</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
