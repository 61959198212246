// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import RelatedResources from '../relatedResources';
import ScheduleForm from '../scheduleForm';
import ShareUrl from './shareUrl';


export default function Blogs15() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Analysis of a Phishing Campaign</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Analysis of a Phishing Campaign</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Analysis of a Phishing Campaign</h3>
                        <p>30-MAY-2022 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Phishing emails can steal sensitive data and cost companies’ reputation. However, protecting a company from these scammers doesn’t need to be difficult.</p>
                            <h4>What do phishing emails do?  </h4>
                            <p>Phishing emails are a social engineering attack used to steal your personal information like passwords or credit card numbers. The victim receives an email appearing to be from a trusted company but which is actually from an imposter.</p>
                            <h4>So, what exactly is phishing? </h4>
                            <p>Phishing is when an attacker mimics a trusted person or brand in an attempt to steal sensitive information, or gain a foothold inside a company network. While phishing emails are by far the most popular, these attacks can also be sent through text message, social media, and even phone calls.</p>
                            <p>These malicious messages are crafted with the goal of the recipient clicking on a link or email attachment that contains malware. Phishing links often redirect to fake login pages that look very similar to legitimate websites. If the victim enters their real login information into the site, the attacker will have a copy of those credentials for themselves.</p>
                            <p>Email attachments work in a similar fashion, but install malware directly on the PC that tried to open the file. This malware can silently collect data and keystrokes and then send this information back to the attacker. This presents an even more dangerous situation where now the attacker can attempt to move further into the network, or create backdoor access to reinfect the network later.</p>
                            <p><u>Sequretek Malware Research Team recently analysed a phishing attempt.</u></p>
                            <p>The campaign starts with an email from a sender posing as an employee of a legitimate company.  The email was sent with a subject line – <strong>REMITTANCE 5/26/2022_101347</strong> to the victim with an attached html phishing page that prompted the user to enter their credentials.</p>
                            <img src='/images/blog-images/sample126.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p><strong>Figure 1: Phishing email from a genuine domain</strong></p>
                            <img src='/images/blog-images/sample127.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p><strong>Figure 2: Attempt to obtain victim ID</strong></p>
                            <br />
                            <p>After signing in with a valid e-mail id and password, user gets an error message and, in the background, credential information is saved to the sender website <strong>“h**ps://cvet-dom.ru/01/workflow.php”.</strong></p>
                            <img src='/images/blog-images/sample128.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p><strong>Figure 3: Capturing victim password</strong></p>
                            <br />
                            <p>On further investigation, the team found that the attacker’s server stored the list of compromised credentials in a text file with victims’ email IDs, passwords, and public IP addresses.  It is presumed that these credentials were checked for their validity and noted accordingly. This is indicated in the screenshot shared below:-</p>
                            <img src='/images/blog-images/sample129.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p><strong>Figure 4: A listing of harvested compromised credentials</strong></p>
                            <br />
                            <p>On the attacker’s server we also discovered a PHP script that was presumably collecting these stolen credentials, validating them for correctness and then storing them on the server in the file x.txt.</p>
                            <img src='/images/blog-images/sample130.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p><strong>Figure 5: Files present on the attacker’s server</strong></p>
                            <br />
                            <h4>Precautionary Measures to Safeguard Against Phishing Attacks</h4>
                            <ul>
                                <li><p>While phishing is a formidable threat, not all emails are harmful & neither is simply viewing a phished email. For the attack to work you need to perform some action such as clicking on the link or opening an attachment. If noticed carefully there are always some indications that help identify a phishing attack.</p></li>
                                <li><p>Be skeptical of any mail with a generic greeting—e.g. Dear Sir/Madam/Client. If the mail is from a trusted and familiar organization, you will be addressed by name.</p></li>
                                <li><p>Be suspicious of any email that urges you to take immediate action such as clicking on a link or providing certain details or downloading and installing an attachment. The attacker tries to create a fake sense of urgency to catch you off-guard.</p></li>
                                <li><p>Phishing emails are not professionally written and are only created with the intent of deception and create a sense of urgency. Grammar and spellings are therefore the least of the attacker’s concern; as a result, phishing emails tend to have spelling and grammar mistakes.</p></li>
                                <li><p>Prior to clicking on any links hover your mouse over the link to see the displayed address link and if it matches the link mentioned in the email. If it does not, it is probably an attack. Never download email attachments you weren’t expecting.</p></li>
                            </ul>
                            <p>“It becomes easier to lure victim to establish trust on a phishing page if a legitimate server is being used”</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Analysis of a Phishing Campaign' , url: 'https://sequretek.upriselabs.io/resources/blog/analysis-of-a-phishing-campaign'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
