// ############################ Require packages here ############################
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './style.scss';
import ScheduleForm from '../scheduleForm';
import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';


const challenges = [{ image: '/images/flag.svg', title: 'Strict Regulations', description: 'Adherence to mandates like GLBA, HIPAA, GDPR, and IRDA is essential. Non-compliance can result in heavy penalties and potential legal action.' },
{ image: '/images/legacy.svg', title: 'Legacy Systems', description: 'Outdated legacy systems with lax security protocols are no match for modern cyberthreats, leaving them vulnerable to known attacks.' },
{ image: '/images/head.svg', title: 'Intellectual Property (IP)', description: 'IP such as underwriting algorithms are valuable targets for cybercriminals seeking a competitive advantage.' },
{ image: '/images/rotate-exclamation.svg', title: 'Threat Evolution', description: 'Ever-evolving attack techniques necessitate insurers to stay vigilant and invest in threat intelligence for proactive countering.' },
{ image: '/images/interactive.svg', title: 'Digital Transformation', description: 'Rapid adoption of new technologies such as mobile apps and portals brings convenience, but creates security vulnerabilities.' },
{ image: '/images/customer-care.svg', title: 'Third-Party Service Providers', description: `Weak security practices by third-party vendors handling data processing and customer support leave insurers' IT infrastructure vulnerable.` },
{ image: '/images/information.svg', title: 'Personal Information', description: `The vast amounts of personal and user behavior data that insurance companies handle to personalize premiums attract cybercriminals.` },
{ image: '/images/queue.svg', title: 'Unaware Workforce', description: `Companies with under-trained or unaware employees are vulnerable to exploitation by cyber adversaries.` },
{ image: '/images/time.svg', title: 'Procrastination', description: `Apathy and hubris leading to delayed cybersecurity measures, exposes insurers to attackers.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (PCTEM) continuously monitors insurers’ IT infrastructure for threats, and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: `Sequretek's user access governance solutions control access to sensitive data with regular reviews and activity monitoring.` },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: 'Sequretek’s CTEM platform’s governance and tailored compliance reports help meet complex insurance regulations from various regulatory bodies and acts.' },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]


export default function Insurance() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4800);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad0791e5c52d1577f9163']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Fortress of Trust | Protect Insurer Data & Brand Reputation</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore how Sequretek’s cyber insurance solutions protect your sensitive data, prevent financial losses, and secure your brand’s reputation." />
                <meta name="keywords" content="Cyber risk management, Cyber insurance" />
                <meta property="og:title" content="Sequretek | Fortress of Trust | Protect Insurer Data & Brand Reputation" />
                <meta property="og:description" content="Explore how Sequretek’s cyber insurance solutions protect your sensitive data, prevent financial losses, and secure your brand’s reputation." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: ` linear-gradient(41.44deg, #040c30 1.6%, rgba(6, 6, 93, 0.1) 10.69%, #06065d 76.56%),url('/images/bg-img1.jpeg')` }}>
                <div className='seq-description'>
                    <h5 data-aos="fade-up">Industries{' > '}Insurance </h5>
                    <h2 data-aos="fade-up">Fortress of Trust:
                        <p>Secure Policyholder Data, Protect Your Brand</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row  me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Ticking Clock: <br />
                                The Insurance Sector's Race Against Cybercrime
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>The insurance industry faces a major security challenge: <br />
                                safeguarding a vast amount of sensitive data, from social security numbers to health records, that cybercriminals covet.<br />
                                Reports show a surge in cyberattacks targeting insurers, leading to financial losses, regulatory issues, and damaged trust. The cost of remediation and reputational harm can be immense. Sequretek, with its advanced cyber risk management and threat management solutions, helps you stay protected around the clock.</p>
                        </div>
                    </div>
                </div>

            </div>
            {/* section 3 CYBERCRIMINALS */}
            <div className='seq-content-2' data-aos="fade-up" style={{ backgroundImage: `linear-gradient(179.04deg, #040C30 24.36%, rgba(4, 12, 48, 0.7) 50.37%, #050D31 70.81%),url('/images/bg-img2.png')` }}>
                <div>
                    <h3>Cybercriminals Know No Geographical Boundaries <br />
                        <p> Spotlight on US and India</p>
                    </h3>
                    {window.innerWidth > 767 ?
                        <div className='container mt-4'>
                            <Table responsive>
                                <thead>
                                    <th></th>
                                    <th><img src='/images/usa-flag.svg' alt='' />&nbsp;USA</th>
                                    <th><img src='/images/ind-flag.svg' alt='' />&nbsp;India</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/globe.svg' alt='' /> &nbsp;&nbsp;<span>Market<br /> Landscape</span>
                                            </div>
                                        </td>
                                        <td> $1.2 trillion in premiums in 2022 with a significant increase y-o-y.</td>
                                        <td>Projected CAGR of 4.39% between 2024 and 2028, a 15% rise in cyberattacks on insurers in 2023.</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/flag.svg' alt='' /> &nbsp;&nbsp;<span> Regulators<br /> and Acts</span>
                                            </div>
                                        </td>
                                        <td>Gramm-Leach-Bliley Act (GLBA), Health Insurance Portability and Accountability Act (HIPAA).
                                        </td>
                                        <td>Insurance Regulatory and Development Authority of India (IRDAI), The Information Technology Act (2000).</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <img src='/images/money.svg' alt='' /> &nbsp;&nbsp;<span> Penalties</span>
                                            </div>
                                        </td>
                                        <td>Fines from thousands to millions of dollars; potential consumer lawsuits against the insurer.</td>
                                        <td>Financial penalties; imprisonment of perpetrators of major security breaches.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div> :
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Market Landscape
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <ul>
                                            <li><img src='/images/usa-flag.svg' alt='' /> <span className='ms-3'>$1.2 trillion in premiums in 2022 with a significant increase y-o-y.</span></li>
                                            <li><img src='/images/ind-flag.svg' alt='' /> <span className='ms-3'>Projected CAGR of 4.39% between 2024 and 2028, a 15% rise in cyberattacks on insurers in 2023.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Regulators and Acts
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <ul>
                                            <li><img src='/images/usa-flag.svg' alt='' /> <span className='ms-3'>Gramm-Leach-Bliley Act (GLBA), Health Insurance Portability and Accountability Act (HIPAA).</span></li>
                                            <li><img src='/images/ind-flag.svg' alt='' /> <span className='ms-3'>Insurance Regulatory and Development Authority of India (IRDAI), The Information Technology Act (2000).</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Penalties
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <ul>
                                            <li><img src='/images/usa-flag.svg' alt='' /> <span className='ms-3'>Fines from thousands to millions of dollars; potential consumer lawsuits against the insurer.</span></li>
                                            <li><img src='/images/ind-flag.svg' alt='' /> <span className='ms-3'>Financial penalties; imprisonment of perpetrators of major security breaches.</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* section 4 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Challenges faced</p>
                <h3>Coverage Gaps: Top Risks in the Insurance Industry</h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row  me-0 seq-card-items-row'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='col-md-9 col-sm-12 pe-3 seq-challenge-img' data-aos="fade-up">
                                    <div className='seq-challenge-1' style={{ height: 315, backgroundImage: `url('/images/bg-img3.svg')` }}>
                                        <h3 >Feeling <br />Overwhelmed?</h3>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>

            </div>
            {/* section 5 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps '>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: window.innerWidth > 767 ? `url('/images/bg-img4.svg')` : `url('/images/bg-img47.svg')` }}>
                        <div className='row me-0 '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>Unlock Peace of Mind with Sequretek’s Tailored Cyber Solutions</h3>
                                <button onClick={() => setPage()}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </div>
            </div>
            {/* section 6 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'insurance'}
                />
            </div>
            {/* section 7 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4'>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/manwithumbrella.svg'}
                            mobilebg={'/images/bg-img23.svg'}
                            page='insurance'
                        />
                    </div>
                </div>
            </div>
            {/* section 8 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='insurance'
                                    type=''
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}

