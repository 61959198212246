// ############################ Require packages here ############################
import React, { Fragment, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import { submitDownloadForm } from '../../../Redux/ActionCreators/resourcesAction';

export default function FactsheetModal({ data, type, isOpen, setIsOpen, pdfUrl }) {
    const [isSuccess, setIsSuccess] = useState(false);
    const details = { name: '', email: "", newsletter: true };

    const dispatch = useDispatch();

    const validationSchema = Yup.object({
        name: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Name should only contain alphabets, numbers, and spaces')
            .required('Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
    });

    const handleSubmit = async (values) => {
        let obj = {
            name: values?.name,
            email: values?.email,
            resource_id: data?._id,
            type: type,
            newsletter: values?.newsletter
        }
        const res = await dispatch(submitDownloadForm(obj));
        if (res?.type === 'DOWNLOAD_FORM') {
            setIsSuccess(true);
            downloadFile();
        }
    }

    const getFileName = (type) => {
        switch (type) {
            case 'advisory':
                return 'Threat Advisory.pdf';
            case 'datasheet':
                return 'Datasheet.pdf';
            case 'report':
                return 'Reports.pdf';
            case 'infographic':
                return 'Infographics.pdf';
            case 'case-study':
                return 'Case Studies.pdf';
            case 'whitepaper':
                 return 'White Papers.pdf';
            case 'factsheet':
                return 'Factsheet.pdf';
            default:
                return 'Document.pdf';
        }
    };

    const downloadFile = async () => {
        try {
            const response = await fetch(pdfUrl);

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = getFileName(type);
            // document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Download failed:', error);
        }
    };


    return (
        <div>
            {/* ##############Factsheet downlaod modal ########################### */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-label-vcenter"
                centered
                dialogClassName={"unlock-modal"}
            >
                <Modal.Body style={{ position: 'relative', padding: '35px 40px' }}>
                    {!isSuccess ?
                        <Fragment>
                            <img src='/images/unlock.svg' alt='' />
                            <h4>Download {type === 'factsheet' ? 'Factsheet' : type === 'datasheet' ? 'Datasheet' : type === 'advisory' ? 'Threat Advisory' : type === 'media' ? 'Media Kit': type === 'report' ? 'Reports' : type === 'infographic' ? 'Infographics' : type === 'case-study' ? 'Case Studies' : type === 'whitepaper' ? 'White Papers' : null}</h4>
                            <p>To access this {type === 'factsheet' ? 'factsheet' : type === 'datasheet' ? 'datasheet' : type === 'advisory' ? 'threat advisory' : type === 'media' ? 'media kit' : type === 'report' ? 'Reports' : type === 'infographic' ? 'Infographics' : type === 'case-study' ? 'Case Studies' : type === 'whitepaper' ? 'White Papers'  : null} enter your business email </p>
                            <div
                                className="sticky-close"
                                onClick={() => setIsOpen(false)}
                            >
                                <img src="/images/close-white.svg" width="15px" alt="" />
                            </div>
                            <div className=''>
                                <Formik initialValues={details} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                    {({ isSubmitting }) => (
                                        <Form className="user_form">
                                            <div className="row mt-2">
                                                <div className="col-12 col-md-12 mt-2">
                                                    <div className="form-group">
                                                        <Field type="text" name="name" className="form-control" placeholder="*Name" />
                                                        <ErrorMessage name="name" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="email" className="form-control" placeholder="*Business Email" />
                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 mt-2">
                                                <div className='form-group'>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="checkbox"
                                                            name='newsletter'
                                                        // value={false}

                                                        />
                                                        <label className="form-check-label" htmlFor="checkbox">
                                                            Receive the latest threat advisory and newsletters from Sequretek
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" disabled={isSubmitting} className=' w-100 button-color h-48' >{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Fragment>
                        :
                        <div className='success-text text-center pt-3'>
                            <img src='/images/ellipse.svg' alt='' />
                            <p className='text-center my-4'>{type === 'factsheet' ? 'Factsheet' : type === 'datasheet' ? 'DataSheet' : type === 'advisory' ? 'The latest threat advisory' : type === 'report' ? 'Reports' : type === 'infographic' ? 'Infographics' : type === 'case-study' ? 'Case Studies' : type === 'whitepaper' ? 'White Papers' : null} has been emailed to you. Please check your spam if you don't see it in your inbox. Thank you.</p>
                            <button className='button-color  mt-2' onClick={() => {
                                setIsOpen()
                                setIsSuccess(false)
                            }} >Ok</button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}
