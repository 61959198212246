// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import './resources.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';

export default function Blogs4() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Cybercrime Resurgence: How Disrupted Groups Are Fighting Back</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Delve into the world of cybercrime resurgence. Learn about high-profile groups like Qakbot, Emotet, and LockBit and how they continue to pose significant threats despite law enforcement efforts." />
                <meta name="keywords" content="cyberattacks, cyber threats, cyber security, data breaches" />
                <meta property="og:title" content="Cybercrime Resurgence: How Disrupted Groups Are Fighting Back" />
                <meta property="og:description" content="Delve into the world of cybercrime resurgence. Learn about high-profile groups like Qakbot, Emotet, and LockBit and how they continue to pose significant threats despite law enforcement efforts." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Reemergence of disrupted cybercrime groups is a spectrum</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Reemergence of disrupted cybercrime groups is a spectrum</h3>
                        <p>29-FEB-2024</p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample8.jpg' width={'100%'} height={318} alt='' />
                            <p>A dynamic situation in the realm of cybersecurity where previously disrupted cybercrime groups are regaining activity, albeit in different forms and extents.</p>
                            <img src='/images/blog-images/sample11.png' className='mt-3' height={318} alt='' style={{ width: window.innerWidth > 767 ? '50%' : '100%' }} />
                            <p>Well, of course, cybercrime groups have a shelf life. They can’t sustain forever and get away with doing whatever that they do. The clock inevitably runs out on cybercrime groups, thanks to our defenders who put efforts to keep them in check. Seizure is carried out confiscating whatever the authorities can get their hands on. Meaning, this leaves some holes in the stop gap measures.</p>
                            <p>Not exactly Christmas, is it?</p>
                            <p>Considering connections to other affiliates, availability of information, bad economy and if that is not enough then some only need a hobby, cybercrime groups cling on like barnacles to a ship. Some cybercrime groups are motivated enough to take these disruptions as a minor setback. It can take time for groups to rebuild or adapt after disruptions. While not all cybercrime groups manage to return, some resumed their activities on a small scale to avoid making a spectacle of themselves, some might disband or become significantly less active, while some might go full throttle and mutate into more harmful groups, using smarter evasion techniques or even regrouping with other affiliates. The reemergence of cybercrime groups is not uniform or consistent across all groups. Instead, it may vary in terms of tactics, targets, or the extent of their resurgence.</p>
                            <p>Let’s get acquainted with some of the determined cybercrime groups.</p>
                            <h4>Qakbot</h4>
                            <p>Qakbot, also known as QBot or Pinkslipbot, emerged in April 2021 as a trojan malware aimed at pilfering credentials and executing Automated Clearing House (ACH), wire, and credit card fraud. Initially attributed to the financially motivated threat group Gold Lagoon, Qakbot’s development has evolved over time without exclusive ties to any single threat actor or group. Its evolution has seen it transform into a sophisticated malware implant capable of lateral movement and serving as a launching pad for ransomware deployment as it gained traction and popularity.</p>
                            <p>In August 2023, the U.S. Department of Justice (DoJ) and the FBI collaborated to dismantle Qakbot, marking a landmark technological and financial operation by the DoJ against a botnet. This initiative involved seizing control of Qakbot’s online infrastructure and obtaining court orders to eliminate the malware from approximately 700,000 infected devices worldwide, including 200,000 computers in the U.S. Additionally, the DoJ announced the confiscation of over $8.6 million in illicit cryptocurrency profits.[i]</p>
                            <p>In December 2023, the fifth iteration of Qakbot emerged after over 15 years of development. Since early August 2023, the actors responsible for Qakbot have orchestrated a campaign, disseminating Ransom Knight ransomware and the Remcos backdoor through phishing emails. Experts suggest that Qakbot had evolved into a critical infiltration mechanism for numerous prominent ransomware factions, such as Conti, ProLock, Egregor, REvil (also known as Sodinokibi), DoppelPaymer, and MegaCortex.</p>
                            <p>It’s noteworthy that this activity commenced prior to the FBI’s seizure of Qakbot infrastructure in late August and has persisted since then. This suggests that the law enforcement operation might not have affected the Qakbot operators’ spam delivery infrastructure but rather only their command and control (C2) servers. This likelihood is reinforced by the fact that the developers remain at large and operational, indicating the potential for them to reconstruct the Qakbot infrastructure. Talos evaluates with moderate confidence that the threat actors associated with Qakbot are still active and have initiated a fresh campaign just before the takedown. This campaign involves the distribution of a variant of Cyclops/Ransom Knight ransomware alongside the Remcos backdoor. [ii]</p>
                            <h4>Emotet</h4>
                            <p>Emotet, identified as a botnet orchestrated by the cybercrime group TA542 (also known as Gold Crestwood or Mummy Spider), compromises computers and devices infected with malware, remotely controlled by malicious actors. Initially recognized as a banking trojan upon its emergence in 2014, Emotet has undergone significant evolution, transforming into a malware distributor. Its operations expanded to include spam dissemination and the initiation of Distributed Denial of Service (DDoS) attacks. By 2020, Emotet had ascended to become one of the foremost malware strains globally, renowned for its capability to infiltrate victims’ systems and facilitate the installation of additional malicious software such as TrickBot, Ryuk, and IcedID.[i]</p>
                            <p>On January 27th, 2021, EUROPOL disclosed the successful disruption of the Emotet botnet’s infrastructure, along with the arrest of gang members through coordinated efforts by law enforcement agencies spanning Europe and North America. Authorities seized control of the infrastructure, dismantling it from within through a combination of legal measures and technical interventions, including gaining access to and taking control of the Command and Control (C&C) server.[ii]</p>
                            <p>Emotet revived in November 2021, initiating numerous spam campaigns. From late 2021 to mid-2022, Emotet predominantly propagated via malicious MS Word and MS Excel documents containing VBA macros. However, Microsoft’s move in July 2022 altered the landscape, disabling VBA macros in internet-obtained documents, affecting malware families like Emotet and Qbot that relied on phishing emails with malicious attachments for distribution.</p>
                            <p>In 2023, Emotet conducted three distinct malspam campaigns, each exploring different avenues of intrusion and social engineering techniques. Nevertheless, the diminishing scale of attacks and evolving strategies hint at potential dissatisfaction with the outcomes. Despite warnings, Emotet strategically embedded lures into MS OneNote, enticing users to click, further expanding its reach.</p>
                            <p>Following its resurgence, Emotet underwent multiple upgrades. Notably, the botnet altered its cryptographic scheme and introduced new obfuscations to safeguard its modules. Emotet’s operators invested considerable resources into evading monitoring and tracking efforts since their return. Additionally, they introduced new modules and enhanced existing ones to sustain profitability amidst evolving cybersecurity measures.[iii]</p>
                            <h4>Egregor</h4>
                            <p>In May 2019, the Maze ransomware emerged, but its activity surged notably in November of the same year. It marked a significant shift in ransomware tactics when the operation incorporated a double-extortion strategy, which garnered attention for its media-savvy approach. This innovative technique was swiftly adopted by prominent ransomware groups such as REvil, Clop, and DoppelPaymer, all of whom launched their own data leak platforms. By September 2020, rumors began circulating that Maze was preparing to cease its ransomware activities. The threat actors behind Maze disclosed their modus operandi, involving network compromises and the theft of Windows domain credentials to execute ransomware attacks. Maze gradually halted the encryption of new victims by September 2020, aiming to extract final ransom payments. Consequently, numerous affiliates of Maze transitioned to a newly emerging ransomware operation known as Egregor. Egregor commenced its operations around mid-September, coinciding with the winding down of Maze’s encryption activities.</p>
                            <p>In September, a fresh ransomware entity emerged coinciding with the closure of operations by the Maze ransomware group. Dubbed Egregor, this ransomware utilizes stolen data to coerce victims into paying ransom, mirroring Maze’s modus operandi. Egregor’s ransomware variant appears to be a replica of the Sekhmet strain, previously employed by the Maze syndicate. Many industry analysts have observed that following Maze’s cessation, numerous affiliates transitioned to Egregor. It’s highly likely that Egregor represents a rebranding effort orchestrated by former leaders of the Maze operation.[iv]</p>
                            <p>On February 9, 2021, collaborative efforts between US, Ukrainian, and French authorities led to the apprehension of individuals linked to the Egregor gang, including affiliates associated with their network. The reported capture of the Egregor group’s leader accompanied the shutdown of their website. However, it remains uncertain whether these measures have permanently dismantled Egregor.[v]</p>
                            <p>During a proactive year dedicated to dismantling ransomware syndicates, Ukrainian law enforcement, in collaboration with French authorities, apprehended numerous individuals linked to the Egregor operation. The coordinated effort in February 2021 successfully captured suspects affiliated with Egregor who were engaged in hacking activities utilizing the gang’s ransomware variant.</p>
                            <p>Following the arrests, the dark web leak site managed by Egregor abruptly ceased operations. It’s unclear whether this unexpected shutdown indicates that the leaders of the operation were alarmed, or if the Ukrainian law enforcement operation was more extensive than initially disclosed. According to CSO, the apprehension of Egregor’s leader might have coincided with the authorities’ crackdown. [vi]</p>
                            <p>It’s possible that Egregor could resurface with a fresh identity, and its absence could signify a temporary pause. Yet, indications suggest shifts in their targeted industries and methodologies. While no definitive reports of Egregor’s activities exist beyond 2021, remnants of their network might be functioning under altered aliases. Presently, the Egregor ransomware collective displays high activity, transitioning from targeting online gaming to focusing on the retail industry. Looking ahead, it may continue adjusting its scope of targets.[vii]</p>
                            <h4>DoppelPaymer</h4>
                            <p>In 2019, a new ransomware strain emerged, marking its debut in cybercriminal circles. Targeting organizations and critical infrastructure across various industries, this ransomware, dubbed DoppelPaymer, originated from the BitPaymer ransomware and is linked to the Dridex malware family. What sets DoppelPaymer apart is its distinctive tool, designed to subvert defense mechanisms by terminating security-related processes within the compromised systems. The onslaught of DoppelPaymer attacks was facilitated by the widespread presence of the EMOTET malware.</p>
                            <p>German authorities conducted a raid on the residence of a German citizen suspected of significant involvement in the DoppelPaymer ransomware group. The seized equipment is now being scrutinized by investigators to ascertain the suspect’s specific role within the ransomware group’s hierarchy. Concurrently, despite Ukraine’s ongoing security challenges following the Russian invasion, Ukrainian law enforcement officials interrogated a Ukrainian individual believed to be a core member of the DoppelPaymer group. Two locations, one in Kiev and one in Kharkiv, were searched by Ukrainian officers, who confiscated electronic devices. The seized equipment is presently undergoing forensic analysis.[viii]</p>
                            <p>The absence of updates on their leak site since May 6 suggested that the DoppelPaymer gang was retreating, possibly waiting for public attention on ransomware attacks to diminish. However, recent findings by security researchers revealed that Grief and DoppelPaymer were synonymous, sharing identical encrypted file formats and utilizing the Dridex botnet for distribution. Despite efforts to portray Grief as a distinct RaaS entity, its close resemblance to DoppelPaymer leaves no doubt about their connection. Presently, over two dozen victims are listed on the Grief leak site, indicating that the threat actor has been active under the new alias. Additionally, the gang purportedly took responsibility for the recent attack on the Greek city of Thessaloniki, substantiating their claim with published file archives demonstrating the breach.[ix]</p>
                            <h4>DarkSide to BlackMatter to BlackCat</h4>
                            <h4>DarkSide to BlackMatter</h4>
                            <p>DarkSide, active from August 2020 to May 2021, evolved into BlackMatter from July to November 2021. The notorious DarkSide ransomware operation, responsible for the Colonial Pipeline attack, ceased due to law enforcement actions prompted by the incident. US authorities swiftly retaliated, seizing control over critical infrastructure, including bitcoin wallets containing the $4.4 million ransom. DarkSide made a crucial mistake, allowing decryption of Windows-encrypted data without payment. Feeling the heat, DarkSide went silent until late July 2021, resurfacing as BlackMatter.[x]</p>
                            <p>They returned as BlackMatter on July 31st but soon shut down in November 2021 after Emsisoft exploited a weakness to create a decryptor and servers were seized.</p>
                            <h4>BlackMatter to BlackCat</h4>
                            <p>BlackMatter innovated their ransomware tactics despite DarkSide’s prior missteps. BlackMatter’s new ransomware payload enabled them to restore victims’ data without requiring payment.[xi]</p>
                            <h4>BlackCat/BlackMatter connection</h4>
                            <p>In a recent interview with a BlackCat representative conducted by Recorded Future, it was confirmed that there exists a connection between the two entities. However, there is no indication of any rebranding or direct affiliation. The BlackCat representative elucidated that the operators function as affiliates of other Ransomware-as-a-Service (RaaS) operations. These actors leverage their previous experience gained from involvement in other groups.</p>
                            <p>During the investigation of a BlackCat ransomware incident in December 2021, we identified a domain (along with corresponding IP addresses) used to maintain persistent access to the network. Interestingly, this domain had also been utilized in a BlackMatter attack in September 2021. Further examination revealed various similarities, including tools, file names, and techniques shared between both ransomware variants.</p>
                            <p>Affiliates are primarily responsible for breaching systems and deploying ransomware. Thus, it is plausible for attacks carried out by the same ransomware family to exhibit variations in techniques and procedures. Nonetheless, RaaS operators typically provide training materials, techniques, and tools to their affiliates, akin to the leaked Conti ransomware playbook highlighted by Talos in a prior blog post. This suggests some degree of uniformity across affiliates.</p>
                            <p>One distinguishing factor among RaaS affiliates is the command and control (C2) infrastructure employed for specific attacks. However, the discovery of overlapping C2 addresses used in both the BlackMatter and BlackCat attacks suggests, with moderate confidence, that the same affiliate orchestrated both incidents.</p>
                            <p>This correlation implies that a BlackMatter affiliate likely transitioned early — perhaps within the first month of operation — to BlackCat. Such findings further bolster the speculation regarding the close connections between BlackMatter and BlackCat.[xii]</p>
                            <h4>LockBit</h4>
                            <p>The GOLD MYSTIC threat group, under the guise of LockBit ransomware, started operations in 2019. They rebranded in 2020 and gradually expanded their victim count. LockBit 2.0, introduced in June 2021, saw a surge in victims due to improved functionality. The group used ransomware-as-a-service to target organizations globally. On February 19, 2024, the NCA and FBI dismantled LockBit’s infrastructure, gaining control of their admin environment and leak site. The FBI, along with global law enforcement allies, disrupted the infrastructure of LockBit RaaS. The NCA seized control of LockBit’s main admin environment and their dark web leak site. Instead of hosting victim data, the site will now expose LockBit’s operations daily. Additionally, the NCA acquired LockBit’s source code and extensive intelligence on their activities and associates. Europol’s coordinated effort led to arrests in Poland and Ukraine, freezing of 200+ cryptocurrency accounts, and criminal charges against LockBit affiliates. US indictments target Russian nationals involved in LockBit schemes.[xiii]</p>
                            <p>During the disruption, law enforcement seized the leak site and its mirrors, and notified affiliates through the LockBit panel about the NCA’s awareness. The website hosting victim data remains accessible. At 6:30 am EST on February 20, the seizure notice on the LockBit leak site was substituted with explanatory tiles resembling the original site. The operation disrupted LockBit ransomware by dismantling its infrastructure, targeting individuals, seizing funds, and pledging additional sanctions.</p>
                            <p>Affiliates easily switch between RaaS operations, prioritizing monetization over loyalty. LockBitSupp’s posts show ransomware operators understand creating an ecosystem matters more than the ransomware itself. They offer means for affiliates to profit from any access, even with other ransomware variants. LockBit’s demise won’t eliminate affiliates; they’ll likely join other schemes. More creative efforts are needed to curb ransomware groups effectively.[xiv]</p>
                            <p>Groups that ceased operations voluntarily without being seized and rebranded.</p>
                            <p><strong>GandCrab to REvil:</strong> The GandCrab ransomware operation launched in January 2018 and shut down in June 2019 after claiming to earn $2 Billion in ransom payments. They relaunched as REvil in September 2019, which ultimately shut down in October 2021 after law enforcement hijacked their infrastructure.</p>
                            <p>Maze to Egregor: The Maze ransomware began operating in May 2019 and formally announced its shutdown in October 2020. However, affiliates, and likely the operators, are believed to have rebranded in September as Egregor, who later disappeared after members were arrested in Ukraine.</p>
                            <h4>Conclusion</h4>
                            <p>Taking down cybercrime groups rarely means complete eradication. Law enforcement actions often disrupt infrastructure, arrest key members, or dismantle specific operations, but the groups themselves are rarely entirely “seized.” This is because they are adaptable, capable of rebuilding and re-emerging under different names or with new tactics. Operating within a borderless digital landscape, these groups can pose threats across jurisdictions, making international cooperation crucial in combating them.</p>
                            <p>While the list of disrupted groups isn’t exhaustive, it reflects the constantly evolving nature of cyberthreats. The effectiveness of law enforcement actions varies depending on the target’s structure, resources, and the level of international cooperation. However, these examples highlight the challenges of dismantling cybercrime organizations and the need for continuous vigilance.</p>
                            <p>References</p>
                            <p>[i] https://blogs.blackberry.com/en/2023/10/inside-the-fbi-and-doj-takedown-of-qakbot-the-swiss-army-knife-of-malware</p>
                            <p>[ii] https://blog.talosintelligence.com/qakbot-affiliated-actors-distribute-ransom/</p>
                            <p>[iii] https://www.redscan.com/news/rise-and-fall-emotet-botnet/</p>
                            <p>[iv] https://www.europol.europa.eu/media-press/newsroom/news/world%E2%80%99s-most-dangerous-malware-emotet-disrupted-through-global-action</p>
                            <p>[v] https://securitybrief.asia/story/research-follows-comeback-of-infamous-botnet-emotet</p>
                            <p>[vi] https://news.sophos.com/en-us/2020/12/08/egregor-ransomware-mazes-heir-apparent/</p>
                            <p>[vii] https://www.csoonline.com/article/570215/egregor-ransomware-group-explained-and-how-to-defend-against-it.html</p>
                            <p>[viii] https://www.csoonline.com/article/570215/egregor-ransomware-group-explained-and-how-to-defend-against-it.html</p>
                            <p>[ix] https://cyware.com/news/egregor-a-new-ransomware-gang-on-the-rise-67189a88</p>
                            <p>[x] https://www.europol.europa.eu/media-press/newsroom/news/germany-and-ukraine-hit-two-high-value-ransomware-targets</p>
                            <p>[xi] https://www.bleepingcomputer.com/news/security/doppelpaymer-ransomware-gang-rebrands-as-the-grief-group/</p>
                            <p>[xii] https://www.emsisoft.com/en/blog/39181/on-the-matter-of-blackmatter/</p>
                            <p>[xiii] https://www.emsisoft.com/en/blog/39181/on-the-matter-of-blackmatter/</p>
                            <p>[xiv] https://blog.talosintelligence.com/from-blackmatter-to-blackcat-analyzing/</p>
                            <p>[xv] https://www.nationalcrimeagency.gov.uk/news/nca-leads-international-investigation-targeting-worlds-most-harmful-ransomware-group?ref=news.risky.biz</p>
                            <p>[xvi] https://www.secureworks.com/blog/lockbit-in-action</p>
                        </div>
                    </div>
                </div>
                <ShareUrl
                    details={{ title: 'Cybercrime Resurgence: How Disrupted Groups Are Fighting Back', url: 'https://sequretek.upriselabs.io/resources/blog/adaptive-strategies-of-cybercrime-groups' }}

                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title={'Reemergence of disrupted cybercrime groups is a spectrum'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
