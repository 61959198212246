// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner, Table } from 'react-bootstrap';
import ReactSwitch from 'react-switch';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';


// ############################ Require files here ###############################

import './resources.scss';
import { TopicLoader } from '../../../Loader/loader';
import { convertDate } from '../../../utils/miscellneous';
import { resetLists, getCategories, searchFromCategory, addCatagory, updateCategory, deletedContent, handleStatus, getTypeCounts, editTypes, getAllResources, getNextResources, getResource, sentAnalytic, getCategoryResources, resetResourceLoader } from '../../../Redux/ActionCreators/resourcesAction';
import ConfirmModal from '../../../utils/confirmModal';
import PreviewImage from '../../../utils/previewImage';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddResource from './Manage-Resources/addResource';
import MultiSelect from '../../../utils/customSelect';
import { getRoles } from '../../../Redux/ActionCreators/usersAction';



export default function Reosurces() {
    const [active, setActive] = useState(((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'sales')) ? 'resource-bank' : 'user');
    const [activeType, setActiveType] = useState('');
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [isAddCategory, setIsAddCategory] = useState(false);
    const [isAddResource, setIsAddResource] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState({
        name: '',
        description: '',
        image: '',
        roles: []
    });
    const [resourceDetails, setResourceDetails] = useState(null);
    const [resources, setResources] = useState(null);
    const [viewResource, setViewResource] = useState(false);
    const [type, setType] = useState(null)
    const [typeDetails, setTypeDetails] = useState({ displayname: "", website: false });
    const [isEditType, setIsEditType] = useState(false);
    const [loadingResource, setLoadingresource] = useState(false);
    const size = 100;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { allRoles } = useSelector(state => state.users);
    const { isLoading, categories, resourceTypes, resourceList, pages, websiteResources } = useSelector(state => state.resources);
    const timerRef1 = React.useRef(null);

    useEffect(() => {
        setActiveType('');
        dispatch(getTypeCounts());
    }, [])

    const validationSchema = Yup.object({
        name: Yup.string()
            .matches(/^[a-zA-Z0-9\s\-()&,]+$/, 'Name should have alphabets, numbers, space, hyphen, brackets, comma and ampersand only')
            .required('Name is required'),
        description: Yup.string().required('Description is required'),
        roles: Yup.array()
            .required('Select at least one role')
            .min(1, 'Select at least one role'),
        image: Yup.mixed()
            .notRequired()
            .test('fileSize', 'File size too large', (value) => {
                if (typeof value !== 'object') {
                    return true
                }
                else if (value) {
                    return value.size <= 1048576; // 1 MB
                }
                return true;
            })

    });

    const searchCategory = async (val) => {
        setQuery(val)
        await dispatch(searchFromCategory(val));
    }

    const handleChange = (id, checked, activeTab) => {
        dispatch(handleStatus(id, !checked, activeTab))
    }

    const userAction = async (e, data, edit) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEdit(edit);
        dispatch(getRoles());
        if (data === null) {
            setIsAddCategory(true);
            setCategoryDetails({
                name: '',
                description: '',
                image: null,
                roles: []
            })
        } else {
            const { name, description, image, roles, _id } = data
            await setCategoryDetails({
                _id,
                name,
                description,
                image: image,
                roles: roles?.map(el => el._id)
            })
            await setIsAddCategory(true);

        }

    }

    // Define the form submission handler
    const handleSubmit = async (values) => {
        const data = new FormData();
        categoryDetails?._id && data.append('_id', categoryDetails?._id)
        data.append('name', values.name);
        data.append('description', values.description);
        typeof values?.image === 'object' && data.append('image', values.image);
        data.append('role_ids', JSON.stringify(values?.roles))
        categoryDetails?._id && data.append('_id', categoryDetails?._id)
        if (isEdit) {
            const res = await dispatch(updateCategory(data));
            if (res.type === 'EDIT_CATEGORY') {
                setIsAddCategory(false);
                dispatch(getCategories());
            }
        } else {
            const res = await dispatch(addCatagory(data));
            if (res.type === 'ADD_CATEGORY') {
                setIsAddCategory(false);
            }
        }
    };

    const deleteContent = (e, id) => {
        e.preventDefault();
        e.stopPropagation();
        setDeleteId(id);
        setIsConfirm(true);
    }

    const removeConfirm = async () => {
        const res = await dispatch(deletedContent(deleteId, active));
        if (res.type === 'DELETE_CONTENT') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }

    const goToTopic = (el) => {
        navigate('/app/resources/topics', { state: { detail: el } })
    }

    const onActiveChange = async (e) => {
        setActive(e);
        setPage(1);
        setQuery('');
        await dispatch(resetLists());
        if (e === 'category') {
            await dispatch(getCategories());
        } else if (e === 'resource-bank') {
            setActiveType('');
            await dispatch(getTypeCounts());
            // await dispatch(getAllResources(1, size, '', ''));
        } else {
            await dispatch(getCategories());
        }
    }

    const typeSchema = Yup.object({
        displayname: Yup.string()
            .matches(/^[a-zA-Z0-9\s\-()&,]+$/, 'Type Name should have alphabets, numbers, space, hyphen, brackets, comma and ampersand only')
            .required('Type Name is required'),
    });


    const editType = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        setTypeDetails(data);
        setIsEditType(true);
    }

    const handleTypeSubmit = async (values) => {
        let obj = {
            _id: typeDetails?._id,
            displayname: values?.displayname,
            website: values?.website
        }

        const res = await dispatch(editTypes(obj));
        if (res?.type === 'EDIT_TYPE') {
            setIsEditType(false);
            setTypeDetails({ displayname: '', website: false });
            await dispatch(getTypeCounts())
        }
    }


    const goToOneResource = async (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        setType(type);
        setPage(1);
        setQuery('');
        setActiveType('resource');
        await dispatch(resetResourceLoader());
        await dispatch(getAllResources('', type))
    }

    const fetchData = async () => {
        setPage(page + 1);
        // await dispatch(getNextResources(page + 1, size, query, type))
    }




    const serachResource = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    };

    const delaySearch = async (val) => {
        dispatch(getAllResources(val, type));
    }


    const backTo = async () => {
        setIsAddResource(false)
        setPage(1);
        setResourceDetails(null)
        await dispatch(getAllResources('', type))
    }

    const addContent = () => {
        setIsAddResource(true);
        setIsEdit(false);
        setResourceDetails(null);
    }

    const editResource = (e, el) => {
        e.preventDefault();
        e.stopPropagation();
        setIsEdit(true);
        setIsAddResource(true)
        setResourceDetails(el);
    }

    const goToResource = async (el, type) => {
        await setViewResource(true);
        await setLoadingresource(true);
        if (type === 'category') {
            const res = await dispatch(getCategoryResources(el._id));
            if (res.type === "GET_CATEGORY_RESOURCES") {
                await setResources({ ...el, resources: res.payload });
                await setLoadingresource(false);
            }
        } else {
            const res = await dispatch(getAllResources('', el.type));
            if (res.type === "GET_RESOURCES") {
                await setResources({ ...el, name: el.displayname, resources: res.payload.data });
                await setLoadingresource(false);
            }
        }
    }

    const openResource = async (id) => {
        let obj = {
            "category_id": resources?._id,
            "resource_id": id
        }
        await dispatch(sentAnalytic(obj));
        setResources({
            ...resources,
            resources: resources.resources.map(resource =>
                resource._id === id
                    ? { ...resource, viewed: true }
                    : resource
            )
        })
        const res = await dispatch(getResource(id));
        if (res?.type === 'GET_RESOURCE') {

            window.open(res?.payload?.url, '_blank');

        }
    }

    const downloadUrl = async (id) => {
        const link = document.createElement('a');
        document.body.appendChild(link);
        const res = await dispatch(getResource(id));
        if (res?.type === 'GET_RESOURCE') {
            // Step 2: Fetch the file content as a blob
            const fileResponse = await fetch(res?.payload?.url);
            const blob = await fileResponse.blob();

            // Step 3: Create a temporary URL for the blob
            const objectUrl = URL.createObjectURL(blob);

            // Step 4: Create an anchor element with the download attribute
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = `${res.payload?.title}.pdf`; // Set a filename for the download

            // Step 5: Programmatically trigger the download
            document.body.appendChild(link);
            link.click();

            // Step 6: Cleanup
            URL.revokeObjectURL(objectUrl);
            document.body.removeChild(link);
        }
    }


    return (

        <div className='seq-resources-holder'>
            {/* {((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'sales')) ? */}
            {/* RESOURCES */}
            {!isAddResource ?
                < Fragment >
                    {!viewResource ?
                        <Fragment>
                            {((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'sales')) ?
                                <div className='resource-btns'>
                                    <Fragment>
                                        <button className={active === 'resource-bank' ? "active-btn" : null} onClick={() => onActiveChange('resource-bank')}>Resource Bank</button>&nbsp; &nbsp;
                                        <button className={active === 'category' ? "active-btn" : null} onClick={() => onActiveChange('category')}>Partner Resources</button>&nbsp; &nbsp;
                                        <button className={active === 'user' ? "active-btn" : null} onClick={() => onActiveChange('user')}>User Resources</button>
                                    </Fragment>
                                </div> :
                                null
                            }
                            {active === 'category' ?
                                <Fragment>
                                    <h2 className="d-flex align-items-center justify-content-end">
                                        {/* Resources */}
                                        <div className='leads-btn'>
                                            <button onClick={(e) => userAction(e, null, false)}  > <img src='/images/plus-white.svg' alt='' width={15} /> &nbsp;&nbsp;Add Category&nbsp;&nbsp; </button>
                                        </div>
                                    </h2>
                                    <div className='users-table-holder'>
                                        <div className='users-header flex-wrap'>
                                            <div className='user-input' style={{ width: '35.8%' }}>
                                                <div className="has-search"   >
                                                    <span className="form-control-feedback">
                                                        <img src="/images/search-icon.svg" alt='' width={15} />
                                                    </span>
                                                    <input type='search' placeholder='Search By Category Name' className='form-control' value={query} onChange={(e) => searchCategory(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='users-table'>
                                            <Table responsive >
                                                <thead>
                                                    <tr className='table-header'>
                                                        {/* <th></th> */}
                                                        <th >Category Name</th>
                                                        <th className='text-center'>Added Date  </th>
                                                        <th className='text-center'>Items</th>
                                                        <th className='text-center'>Status</th>
                                                        <th className='text-center'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoading ?
                                                        <tr>
                                                            <td colSpan={5}> <TopicLoader /> </td>
                                                        </tr>
                                                        :
                                                        categories?.length > 0 ?
                                                            categories?.map(el => {
                                                                return (
                                                                    <tr className='cursor-pointer' onClick={() => goToTopic(el)}>
                                                                        {/* <td><img src='/images/drag.svg' alt='' /></td> */}
                                                                        <td>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='img-box'>
                                                                                    {el.image ? <img src={el.image} alt='' /> : <img src='/images/sample1.png' alt='' />}
                                                                                </div>&nbsp;&nbsp;&nbsp;
                                                                                {el?.name}
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                                        <td className='text-center'>{el.items}</td>
                                                                        <td>
                                                                            <div className=' text-center' >
                                                                                {
                                                                                    <p onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        e.stopPropagation();
                                                                                        handleChange(el._id, el.active, active)
                                                                                    }}> <ReactSwitch
                                                                                            onChange={(e) => { console.log(e) }}
                                                                                            width={30}
                                                                                            height={16}
                                                                                            uncheckedIcon={false}
                                                                                            checkedIcon={false}
                                                                                            onColor='#00B24B'
                                                                                            checked={el.active ? true : false} /><br />
                                                                                        {el?.active ? 'Active' : 'Inactive'}
                                                                                    </p>}
                                                                            </div>
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={(e) => userAction(e, el, true)} />&nbsp;&nbsp;
                                                                            {el.active ? null : <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={(e) => deleteContent(e, el._id)} />}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }) :

                                                            < tr >
                                                                <td colSpan={8}>
                                                                    <div className='empty-list'>
                                                                        <img src='/images/empty.png' alt='' />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Fragment> :
                                active === 'resource-bank' ?
                                    (activeType !== 'resource' ?
                                        <Fragment>
                                            <h2 className="d-flex align-items-center justify-content-end">
                                                {/* Resources Bank */}
                                                <div className='leads-btn'>
                                                    <button onClick={() => addContent()}  ><img src='/images/plus-white.svg' alt='' width={15} /> &nbsp;&nbsp;Add Resource&nbsp;&nbsp; </button>
                                                </div>
                                            </h2>
                                            <div className='users-table-holder'>

                                                <div className='users-table'>
                                                    <Table responsive >
                                                        <thead>
                                                            <tr className='table-header'>
                                                                {/* <th></th> */}
                                                                <th >Display Name</th>
                                                                <th >Resource Type</th>
                                                                <th className='text-center'>Items</th>
                                                                {/* <th className='text-center'>Status</th> */}
                                                                <th className='text-center'>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {isLoading ?
                                                                <tr>
                                                                    <td colSpan={5}> <TopicLoader /> </td>
                                                                </tr>
                                                                :
                                                                resourceTypes?.length > 0 ?
                                                                    resourceTypes?.map(el => {
                                                                        return (
                                                                            <tr className='cursor-pointer' onClick={(e) => goToOneResource(e, el.type)}>
                                                                                {/* <td><img src='/images/drag.svg' alt='' /></td> */}
                                                                                <td>
                                                                                    <div className='text-capitalize d-flex align-items-center'>
                                                                                        {el?.displayname}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className='text-capitalize d-flex align-items-center'>
                                                                                        {el?.type}
                                                                                    </div>
                                                                                </td>
                                                                                <td className='text-center'>{el.count}</td>
                                                                                <td className='text-center'>
                                                                                    <img src='/images/edit-icon.svg' alt='' onClick={(e) => editType(e, el)} width={20} height={20} />&nbsp;&nbsp;
                                                                                    <img src='/images/view-icon.svg' alt='' onClick={(e) => goToOneResource(e, el.type)} width={20} height={20} />
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }) :

                                                                    < tr >
                                                                        <td colSpan={8}>
                                                                            <div className='empty-list'>
                                                                                <img src='/images/empty.png' alt='' />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Fragment> :
                                        <Fragment>
                                            <h2 className="">
                                                {/* Resources Bank */}
                                                <div className='leads-btn d-flex align-items-center justify-content-between'>
                                                    <button style={{ minWidth: 120 }} onClick={() => setActiveType('')}><img src="/images/back-white.svg" alt="" /> &nbsp; Back</button>
                                                    <button onClick={() => addContent()}  ><img src='/images/plus-white.svg' alt='' width={15} /> &nbsp;&nbsp;Add Resource&nbsp;&nbsp; </button>
                                                </div>
                                            </h2>
                                            <div className='users-table-holder'>
                                                <div className='users-header flex-wrap'>
                                                    <div className='user-input' style={{ width: '35.8%' }}>
                                                        <div className="has-search"   >
                                                            <span className="form-control-feedback">
                                                                <img src="/images/search-icon.svg" alt='' width={15} />
                                                            </span>
                                                            <input type='search' placeholder='Search By resource name' className='form-control' value={query} onChange={(e) => serachResource(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    {/* <div className='select-group  '>
                                                     edit   <div>
                                                            <Select
                                                                components={{ DropdownIndicator }}
                                                                options={types}
                                                                isClearable
                                                                onChange={selectType}
                                                                placeholder='By Type'
                                                                value={type}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='users-table'>
                                                    <InfiniteScroll
                                                        dataLength={resourceList?.length} //This is important field to render the next data
                                                        next={pages === 1 ? '' : fetchData}
                                                        hasMore={true}
                                                    >
                                                        <Table responsive >
                                                            <thead>
                                                                <tr className='table-header'>
                                                                    {/* <th></th> */}
                                                                    <th >Resource Title</th>
                                                                    <th className='text-center'>Added Date  </th>
                                                                    <th className='text-center'>Type</th>
                                                                    <th className='text-center'>Status</th>
                                                                    <th className='text-center'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {isLoading ?
                                                                    <tr>
                                                                        <td colSpan={5}> <TopicLoader /> </td>
                                                                    </tr>
                                                                    :
                                                                    resourceList?.length > 0 ?
                                                                        resourceList?.map(el => {
                                                                            return (
                                                                                <tr className='cursor-pointer'>
                                                                                    {/* <td><img src='/images/drag.svg' alt='' /></td> */}
                                                                                    <td>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='img-box'>
                                                                                                {el.image ? <img src={el.image} alt='' /> : <img src='/images/sample1.png' alt='' />}
                                                                                            </div>&nbsp;&nbsp;&nbsp;
                                                                                            {el?.title}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                                                    <td className='text-center text-capitalize'>{el.type === 'media' ? 'Media Coverage' : el.type === 'press' ? 'Press Release' : el.type}</td>
                                                                                    <td>
                                                                                        <div className=' text-center' >
                                                                                            {
                                                                                                <p onClick={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    e.stopPropagation();
                                                                                                    handleChange(el._id, el.active, active)
                                                                                                }}> <ReactSwitch
                                                                                                        onChange={(e) => { console.log(e) }}
                                                                                                        width={30}
                                                                                                        height={16}
                                                                                                        uncheckedIcon={false}
                                                                                                        checkedIcon={false}
                                                                                                        onColor='#00B24B'
                                                                                                        checked={el.active ? true : false} /><br />
                                                                                                    {el?.active ? 'Active' : 'Inactive'}
                                                                                                </p>}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={(e) => editResource(e, el)} />&nbsp;&nbsp;
                                                                                        {el.active ? null : <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={(e) => deleteContent(e, el._id)} />}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }) :

                                                                        < tr >
                                                                            <td colSpan={8}>
                                                                                <div className='empty-list'>
                                                                                    <img src='/images/empty.png' alt='' />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </InfiniteScroll>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) :
                                    active === 'user' ?
                                        <Fragment>
                                            <h2 className={((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'sales')) ? "d-flex align-items-center justify-content-end" : "d-flex align-items-center justify-content-between"} style={{ paddingLeft: 20 }}>
                                                {((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'sales')) ? '' : 'Resources'}
                                                <div className='leads-btn'>
                                                    {/* <button  >Export  </button> */}
                                                </div>
                                            </h2>
                                            <div className='users-table-holder b-none'>
                                                {/* <div className='users-header flex-wrap'>
                                                    <div className='user-input' style={{ width: '35.8%' }}>
                                                        <div className="has-search"   >
                                                            <span className="form-control-feedback">
                                                                <img src="/images/search-icon.svg" alt='' width={15} />
                                                            </span>
                                                            <input type='search' placeholder='Search By category name' className='form-control' value={query} onChange={(e) => searchCategory(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className='cards'>
                                                    <h4 style={{ fontWeight: 600 }}>Partner Resources</h4>
                                                    <div className='row'>
                                                        {categories?.length > 0 ?
                                                            categories?.map(el => {
                                                                return (
                                                                    <div className='col-md-3 mt-3 d-flex align-self-stretch cursor-pointer'>
                                                                        <div class="card" onClick={() => goToResource(el, 'category')} >
                                                                            <img src={el.image ? el.image : "/images/sample2.png"} class="card-img-top" alt="" />
                                                                            <div class="card-body">
                                                                                <h5 class="card-title">{el.name}</h5>
                                                                                <p class="card-text"><img src='/images/item-icon.svg' width={15} alt='' /> {el.items} Items</p>
                                                                                <div className='card-border' >
                                                                                    <p>Know more</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="empty-list">
                                                                <img src="/images/empty.png" alt="" />
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                <div className='cards'>
                                                    <h4 style={{ fontWeight: 600 }}>Website Resources</h4>
                                                    <div className='row'>
                                                        {websiteResources?.length > 0 ?
                                                            websiteResources?.map(el => {
                                                                return (
                                                                    <div className='col-md-3 mt-3 d-flex align-self-stretch cursor-pointer'>
                                                                        <div class="card" onClick={() => goToResource(el, 'type')} >
                                                                            <img src={el.type === 'advisory' ? "/images/advisory-img.jpg" : el.type === 'report' ? "/images/report-img.png" :
                                                                                el.type === 'whitepaper' ? "/images/whitepaper-img.png" : el.type === 'datasheet' ? "/images/datasheet-img.png" : el.type === 'infographic' ?
                                                                                    "/images/infographic-img.png" : el.type === 'video' ? "/images/sample7.png" : el.type === 'event' ? "/images/event-img.png" : el.type === 'blog' ? "/images/blog-img.png" :
                                                                                        el.type === 'case-study' ? "/images/casestudy-img.png" : el.type === 'podcast' ? "/images/podcast-img.png" : "/images/sample2.png"
                                                                            } class="card-img-top" alt="" />
                                                                            <div class="card-body">
                                                                                <h5 class="card-title">{el.displayname}</h5>
                                                                                <p class="card-text"><img src='/images/item-icon.svg' width={15} alt='' /> {el.count} Items</p>
                                                                                <div className='card-border' >
                                                                                    <p>Know more</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) :
                                                            <div className="empty-list">
                                                                <img src="/images/empty.png" alt="" />
                                                            </div>
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </Fragment>
                                        : null
                            }
                        </Fragment> :
                        <Fragment>
                            <h2 className="d-flex align-items-center">
                                <img src='/images/back.svg' alt='' className='cursor-pointer' onClick={() => setViewResource(false)} />&nbsp;&nbsp;{resources?.name}
                            </h2>
                            <div className='users-table-holder'>
                                <div className='users-header flex-wrap'>
                                    <div className='user-input' style={{ width: '35.8%' }}>
                                        <div className="has-search"   >
                                            <span className="form-control-feedback">
                                                <img src="/images/search-icon.svg" alt='' width={15} />
                                            </span>
                                            <input type='search' placeholder='Search By resource name' className='form-control' value={query} onChange={(e) => serachResource(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                {loadingResource ?
                                    <TopicLoader /> :
                                    <div className='users-table'>
                                        <Table responsive >
                                            <thead>
                                                <tr className='table-header'>
                                                    <th></th>
                                                    <th >Resource Title</th>
                                                    <th className='text-center'>Publish Date </th>
                                                    <th className='text-center'>Type</th>
                                                    <th className='text-center'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    resources?.resources?.length > 0 ?
                                                        resources?.resources?.map(el => {
                                                            return (
                                                                <tr className='cursor-pointer'>
                                                                    <td>{el.viewed ? <img src='/images/green-check-fill.svg' alt='' /> : <img src='/images/grey-check-fill.svg' alt='' />}</td>
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='img-box'>
                                                                                {el.image ? <img src={el.image} alt='' /> : <img src='/images/sample1.png' alt='' />}
                                                                            </div>&nbsp;&nbsp;
                                                                            {el?.title}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>{el?.publish ? convertDate(el?.publish) : convertDate(el?.createdAt)}</td>
                                                                    <td className='text-center text-capitalize'>{el.type === 'media' ? 'Media Coverage' : el.type === 'press' ? 'Press Release' : el.type}</td>

                                                                    <td className='text-center'>
                                                                        <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openResource(el._id)} />&nbsp;&nbsp;
                                                                        {((el?.type === 'advisory') || (el?.type === 'report') || (el?.type === 'infographic') || (el?.type === 'datasheet') || (el?.type === 'case-study') || (el?.type === 'whitepaper')) ?
                                                                            <img src='/images/download-icon.svg' alt='' width={20} height={20} onClick={(e) => downloadUrl(el._id)} /> :
                                                                            null
                                                                        }&nbsp;&nbsp;
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :

                                                        < tr >
                                                            <td colSpan={8}>
                                                                <div className='empty-list'>
                                                                    <img src='/images/empty.png' alt='' />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                    </div>}
                            </div>
                        </Fragment>
                    }
                </Fragment> :
                <AddResource
                    backToMain={backTo}
                    isAddFromCategory={false}
                    isEdit={isEdit}
                    resourceDetails={resourceDetails}
                />
            }







            {/* ##############Add modal ########################### */}
            <Modal
                show={isAddCategory}
                size="md"
                aria-labelledby="contained-modal-label-vcenter"
                centered
                dialogClassName={"add-modal"}
            >
                <div className="modal-header">
                    <h5 className="modal-label">{isEdit ? 'Edit Category' : 'Add Category'}</h5>
                    <div
                        className="sticky-close"
                        onClick={() => setIsAddCategory(false)}
                    >
                        <img src="/images/close.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "20px 20px", paddingTop: 0 }}>

                    <div className=''>
                        <Formik initialValues={categoryDetails} validationSchema={validationSchema} onSubmit={handleSubmit}>
                            {({ setFieldValue, values, isSubmitting }) => (
                                <Form className="users_form">
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-12">
                                            <label>Category Name<span> *</span></label>
                                            <div className="form-group">
                                                <Field type="text" name="name" className="form-control" placeholder="Name" />
                                                <ErrorMessage name="name" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <label>Description<span> *</span></label>
                                            <div className="form-group">
                                                <Field type="text" as='textarea' rows={3} name="description" className="form-control" placeholder="Description" />
                                                <ErrorMessage name="description" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <label>Roles<span> *</span></label>
                                            <div className="form-group">
                                                <Field
                                                    name="roles"
                                                    id="singleSelectCustom"
                                                    placeholder="Multi Select Role"
                                                    isMulti={true}
                                                    component={MultiSelect}
                                                    options={allRoles}
                                                />
                                                <ErrorMessage name="roles" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <label>Image</label>
                                            <div className="form-group">
                                                <input type="file" accept=".jpg, .jpeg, .png" name="image" className="form-control" placeholder="Upload Banner"
                                                    onChange={(event) => {
                                                        setFieldValue("image", event.currentTarget.files[0])
                                                    }} />
                                                <div className='d-flex align-items-center mt-3'>
                                                    {typeof values?.image !== 'object' ?
                                                        <img src={values?.image} alt='' width={150} height={80} style={{ borderRadius: 4 }} /> :
                                                        <PreviewImage file={values?.image} />
                                                    }
                                                </div>
                                                <ErrorMessage name="image" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" style={{ width: 170 }} disabled={isSubmitting} className='mt-0 button-color h-40'>{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ##############Edit Type modal ########################### */}
            <Modal
                show={isEditType}
                size="md"
                aria-labelledby="contained-modal-label-vcenter"
                centered
                dialogClassName={"add-modal"}
            >
                <div className="modal-header">
                    <h5 className="modal-label">{'Edit Type Name'}</h5>
                    <div
                        className="sticky-close"
                        onClick={() => setIsEditType(false)}
                    >
                        <img src="/images/close.svg" width="15px" alt="" />
                    </div>
                </div>
                <Modal.Body style={{ padding: "20px 20px", paddingTop: 0 }}>

                    <div className=''>
                        <Formik initialValues={typeDetails} validationSchema={typeSchema} onSubmit={handleTypeSubmit}>
                            {({ setFieldValue, isSubmitting }) => (
                                <Form className="users_form">
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-12">
                                            <label>Type Name<span> *</span></label>
                                            <div className="form-group">
                                                <Field type="text" name="displayname" className="form-control" placeholder="Name" />
                                                <ErrorMessage name="displayname" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 mt-2">

                                            <div className='form-group'>
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkbox"
                                                        name='website'
                                                    // value={false}

                                                    />
                                                    <label className="form-check-label" htmlFor="checkbox">
                                                        Website
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" style={{ width: 170 }} className='mt-0 button-color h-40' disabled={isSubmitting}>{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>

            {/* confirmation Modal */}
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                type={active}
                removeConfirm={removeConfirm}
            />
        </div >
    )
}
