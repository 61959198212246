
const testimonials = [
    {
        name: 'home', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
            // { type: 'external2', description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in... elit, sed do eiusmod tempor in...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in... elit, sed do eiusmod tempor in...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in... elit, sed do eiusmod tempor in...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in... elit, sed do eiusmod tempor in...Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor in... elit, sed do eiusmod tempor in.` , name:'Satya Nadella',job: 'CEO, Microsoft', reviewer: 'Microsoft' },
        ]
    },
    {
        name: 'platform', testimoni: [
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'product-NG-SIEM', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5458746', title: `Tailored Alerts for Devices in the Fight Against Malware Threats`, description: `very good experience as we are getting daily alerts and detailed reports about devices for malware and threat detection.`, date: 'May 8, 2024', job: 'PROJECT MANAGER', function:'Project and Portfolio Management', industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
        
        ]
    },
    {
        name: 'product-identity', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
        ]
    },
    {
        name: 'product-EDR', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-protection-platforms/vendor/sequretek/product/percept-edr/review/view/5461088', title: `Leveraging Compliance Advantage with Cost-Effective Solutions`, description: `Truly fantastic product to use from a compliance perspective.`, date: 'May 9, 2024',job:'IT Associate' , function: 'IT', industry: "Software", reviewer: 'gartner' },
        ]
    },
    {
        name: 'product-compliance-manager', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
        ]
    },
    {
        name: 'partners', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=shXKTPCmRx4' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'smb', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'banking', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456950', title: `Fast Deployment and Easy Integration: A Look at the Newest Security `, description: `Product offered industries latest trends to protect first line of attack surface through various sources.`, date: 'May 7, 2024', job: 'INFORMATION SECURITY',function:'Project and Portfolio Management' , industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5458746', title: `Tailored Alerts for Devices in the Fight Against Malware Threats`, description: `very good experience as we are getting daily alerts and detailed reports about devices for malware and threat detection.`, date: 'May 8, 2024', job: 'PROJECT MANAGER', function:'Project and Portfolio Management', industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
        ]
    },
    {
        name: 'finance', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456950', title: `Fast Deployment and Easy Integration: A Look at the Newest Security `, description: `Product offered industries latest trends to protect first line of attack surface through various sources.`, date: 'May 7, 2024', job: 'INFORMATION SECURITY',function:'Project and Portfolio Management' , industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5458746', title: `Tailored Alerts for Devices in the Fight Against Malware Threats`, description: `very good experience as we are getting daily alerts and detailed reports about devices for malware and threat detection.`, date: 'May 8, 2024', job: 'PROJECT MANAGER', function:'Project and Portfolio Management', industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'insurance', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456950', title: `Fast Deployment and Easy Integration: A Look at the Newest Security `, description: `Product offered industries latest trends to protect first line of attack surface through various sources.`, date: 'May 7, 2024', job: 'INFORMATION SECURITY',function:'Project and Portfolio Management' , industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/managed-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5458746', title: `Tailored Alerts for Devices in the Fight Against Malware Threats`, description: `very good experience as we are getting daily alerts and detailed reports about devices for malware and threat detection.`, date: 'May 8, 2024', job: 'PROJECT MANAGER', function:'Project and Portfolio Management', industry: "Banking", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'manufacturing', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456552', title: `24x7 Vigilance: A Deep Dive into SOC Team's Constant Alertness`, description: `SOC team is very active and we keep getting advisories as well as information about threats 24x7. The team also helps us in closing the incidents.`, date: 'May 7, 2024', job: 'Head of IT',function:'IT Services', industery: "Consumer Goods", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'healthcare', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },

        ]
    },
    {
        name: 'pharma', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', function: 'Chief Information Security Officer', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'retail', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-protection-platforms/vendor/sequretek/product/percept-edr/review/view/5461088', title: `Leveraging Compliance Advantage with Cost-Effective Solutions`, description: `Truly fantastic product to use from a compliance perspective.`, date: 'May 9, 2024',job:'IT Associate' , function: 'IT', industry: "Software", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'technology', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=TH-WAxjZ0ys&t=14s' },
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-protection-platforms/vendor/sequretek/product/percept-edr/review/view/5461088', title: `Leveraging Compliance Advantage with Cost-Effective Solutions`, description: `Truly fantastic product to use from a compliance perspective.`, date: 'May 9, 2024',job:'IT Associate' , function: 'IT', industry: "Software", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5557448', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: 'Percept XDR is flexible and easy to integrate with other products', date: 'Jul 2, 2024',job: 'IT MANAGER', function: 'IT MANAGER', industry: "IT", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },
    {
        name: 'government', testimoni: [
            { type: 'youtube', link: 'https://www.youtube.com/watch?v=gK7dVcj03rA&t=1s' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/5456282', title: `Exploring Percept XDR's Proficiency in Security Breach Responses`, description: 'percept xdr is completely good tool for security services. it helps for threat detection and response easily for any security breach', date: 'May 7, 2024', industry: "Manufacturing",job:'DEPUTY MANAGER IT', function: 'Operations', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/identity-governance-administration/vendor/sequretek/product/percept-iga/review/view/5499930', title: `Percept IGA Streamlines Access Management: Efficiency and Security Spotlights`, description: 'Our experience with percept IGA has been highly satisfactory, particularly in the areas of user access provisioning, de-provisioning and access reviews. The platform has significantly streamlined our identity management process, ensuring that user access is both efficient and secure.', date: 'Aug 5, 2024', industry: "Manufacturing",job:'IT Associate', function: 'IT', reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/extended-detection-and-response/vendor/sequretek/product/percept-xdr/review/view/55574482', title: `Beyond Integration: The Challenges and Benefits of Percept XDR`, description: `Percept XDR is flexible and easy to integrate with other products`, date: 'Jul 2, 2024', job: 'IT MANAGER',function:'IT', industry: "Software", reviewer: 'gartner' },
            { type: 'external', link: 'https://www.gartner.com/reviews/market/endpoint-detection-and-response-solutions/vendor/sequretek/product/percept-edr/review/view/5472732', title: `Highlighting the Flexibility and Reliability of an Outstanding Product`, description: `XDR's Unified view of security data is a game changer. Threat hunting and investigation are much faster and improved threat detection gives us peace of mind. While complex, the insights are invaluable.`, date: 'May 16, 2024', job: 'Chief Information Security Officer', function: 'IT Security and Risk Management', industry: "Telecommunication", reviewer: 'gartner' },
        ]
    },

]

// TESTIMONIAL Data will send what testimonal data need to be show in respective pages

export const getTestimonial = (page) => {
    const testimonial = testimonials.find(item => item.name === page);
    return testimonial ? testimonial.testimoni : [];
}


